import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@stripe/stripe-js';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMobileDetection from 'vue-mobile-detection'

//Fontawsome

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);

import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
// import { far } from '@fortawesome/free-regular-svg-icons';
// library.add(far);
// import { dom } from "@fortawesome/fontawesome-svg-core";
// dom.watch();

import VueGoogleMaps from '@fawmi/vue-google-maps'
import price from './components/price.vue'
import loader from './controls/loader.vue'
import no_records from './components/no_records.vue'
import Tryon from './components/tryon/tryon.js'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
    components,
    directives,
  })

let tryonGlobal = new Tryon();



const app = createApp(App);
app.use(store);
app.use(vuetify)
app.use(router);
app.component("fa", FontAwesomeIcon);
app.use(VueSweetalert2);
app.use(VueMobileDetection);


//app.config.globalProperties.tryon = tryonGlobal;

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyC9H3WZMB17q8ZigpwAJXVyBP0yKatjMk8',
    },
});
app.provide("tryon",tryonGlobal);
app.component("price", price);
app.component("loader", loader);
app.component("no_records", no_records);

// import '../public/css/color_schemes/aqua/style.css';
// import '../public/css/color_schemes/beauty/style.css';
// import '../public/css/color_schemes/bright/style.css';
// import '../public/css/color_schemes/light/style.css';
// import '../public/css/color_schemes/modern/style.css';
// import '../public/css/color_schemes/midnight/style.css';
// import '../public/css/color_schemes/pastel/style.css';

router.isReady().then(() => {
    app.mount('#app')
})
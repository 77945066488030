/* eslint-disable */
import * as face_mesh from '@mediapipe/face_mesh';
//import { POP_SCOPE_ID } from '@vue/compiler-core';

class FaceMeshApi {
    constructor() {
        this.faceMesh=null;
        this.landmarks=null;
        this.data = {
            LeftIris:{
                center:0,
                radius:0
            },
            RightIris:{
                center:0,
                radius:0
            },
            W:0,
            H:0,
            PD:0,
            LeftPD:0,
            RightPD:0,
            Pix2mm:1,
            FaceWidth:0,
            Center:[],
            Width:[]

        }  

        this.faceMesh = new face_mesh.FaceMesh({locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
          }});

          this.faceMesh.setOptions({
            maxNumFaces: 1,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
          });

          this.faceMesh.onResults(this.faceMeshResults);

    }

    toPix(index) {
        return { 
            x:this.landmarks[index].x*this.data.W,
            y:this.landmarks[index].y*this.data.H,
        }
    }
    
    exctractIris(PTS,left) {
        // if(left) console.log('PTS LEFT', JSON.stringify(PTS));
        // else console.log('PTS RIGHT', JSON.stringify(PTS));

        CIRCLEFIT.resetPoints()
        let xx=0;
        let yy=0;
        let rr=[];
        let vals = [];
        for (let k = 0; k<PTS.length; k++) 
        {
            let m=PTS[k][0];
            let p=this.toPix(m)
            vals.push(p);
            
            xx=xx+p.x;
            yy=yy+p.y;

            rr.push(p)
        }

        // if(left) console.log('VALS LEFT', JSON.stringify(vals));
        // else console.log('VALS RIGHT', JSON.stringify(vals));


        let c={
            x:xx/4,
            y:yy/4
        }
        // console.log('center IRIS', JSON.stringify(c));

        let d1=Math.sqrt((rr[0].x-rr[2].x)**2+(rr[0].y-rr[2].y)**2)
        let d2=Math.sqrt((rr[1].x-rr[3].x)**2+(rr[1].y-rr[3].y)**2)
        let r = (d1+d2)/4
        
        if(left) {
            this.data.LeftIris.center=c;
            this.data.LeftIris.radius=r;
        } else{
            this.data.RightIris.center=c;
            this.data.RightIris.radius=r;
        }
    
    }

    calculatePD() {
        let IrisDiamm=12;
        let IrsDiapix = this.data.LeftIris.radius+this.data.RightIris.radius;
        this.data.Pix2mm = IrsDiapix/IrisDiamm;

        let x1=this.data.LeftIris.center.x;
        let y1=this.data.LeftIris.center.y;
        let x2=this.data.RightIris.center.x;
        let y2=this.data.RightIris.center.y;


        this.data.PD = Math.round(Math.sqrt((x2-x1)*(x2-x1)+(y2-y1)*(y2-y1))/this.data.Pix2mm);

        x1=this.data.Center[0].x;
        y1=this.data.Center[0].y;
        x2=this.data.RightIris.center.x;
        y2=this.data.RightIris.center.y;

        this.data.RightPD = Math.round(Math.sqrt((x2-x1)*(x2-x1)+(y2-y1)*(y2-y1))/this.data.Pix2mm);


        x1=this.data.LeftIris.center.x;
        y1=this.data.LeftIris.center.y;
        x2=g_face.data.Center[0].x;
        y2=g_face.data.Center[0].y;

        this.data.LeftPD = Math.round(Math.sqrt((x2-x1)*(x2-x1)+(y2-y1)*(y2-y1))/this.data.Pix2mm);
        // adjusting right and left Pd w.r.t total PD
        let sumPD = this.data.LeftPD + this.data.RightPD;
        this.data.LeftPD = (this.data.LeftPD * this.data.PD) / sumPD;
        this.data.RightPD = (this.data.RightPD * this.data.PD) / sumPD;

        x1=this.data.Width[0].x;
        y1=this.data.Width[0].y;
        x2=this.data.Width[1].x;
        y2=this.data.Width[1].y;
        this.data.FaceWidth = Math.sqrt((x2-x1)*(x2-x1)+(y2-y1)*(y2-y1))/this.data.Pix2mm;

    }

    faceMeshResults(results)
    {
        try {

            g_face.data.W=results.image.width;
            g_face.data.H=results.image.height;
            // console.log('results.image.width', results.image.width);
            // console.log('results.image.height', results.image.height);
            // console.log('results.multiFaceLandmarks', results.multiFaceLandmarks);
            if (results.multiFaceLandmarks) {

                if(results.multiFaceLandmarks.length>0) {
                    let landmarks=results.multiFaceLandmarks[0];
                    g_face.landmarks = landmarks;

                    g_face.exctractIris(face_mesh.FACEMESH_LEFT_IRIS,true);
                    g_face.exctractIris(face_mesh.FACEMESH_RIGHT_IRIS,false);
        
                    let r = (g_face.data.LeftIris.radius+g_face.data.RightIris.radius)/2;
                    g_face.data.LeftIris.radius=r;
                    g_face.data.RightIris.radius=r;

                    g_face.data.Center=[]
                    g_face.data.Center.push(g_face.toPix(6))
                    g_face.data.Center.push(g_face.toPix(9))
                    g_face.data.Center.push(g_face.toPix(10))

                    g_face.data.Width=[]
                    g_face.data.Width.push(g_face.toPix(162))
                    g_face.data.Width.push(g_face.toPix(389))

                    g_face.calculatePD();

                    // console.log(JSON.stringify(g_face.data))
                }

            
            }
        }
        catch(ex) {
            console.log(ex);
        }
    }

}

var g_face= new FaceMeshApi();
var g_faceData = null;


function copy(obj) {
    let copy =Object.assign({}, obj)
    return copy;
}


//=====================================================================

class TryonLib {
    constructor() {
        this.faceapiOK=false;
        this.faceImageHtml=null;
        this.faceB64=null;
        this.ctx=null;
        this.imageScale= 1;
        this.canvas_div=null;
        this.w = { color: "white", lineWidth: 4, radius: 6, visibilityMin: 0.5 }
    }

    init() {
        //Load Models
        return this.loadModels();
    }

    async loadModels() {

        //Not Required
        this.faceapiOK=true;
        return true;
    }


    async AddNewImage(face_b64, storedFaceData=null) {
        this.faceB64 = face_b64;

        let img = await new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = async () => resolve(img)
            img.onerror = () => reject('Failed to load')
            img.src = face_b64
        });

        if(img == 'Failed to load') alert(img);

        this.faceImageHtml = img;
        this.initCanvasImage(img);

        // if(storedFaceData) {
        //     g_faceData = storedFaceData;
        //     console.log("storedFaceData", g_faceData);
        // } else {
            await g_face.faceMesh.send({image: img});
            await g_face.faceMesh.send({image: img});
            g_faceData = g_face.data;

        // }

        return g_faceData;
    }

    initCanvasImage(img,W,H) {
        let w=img.width;
        let h=img.height;
        if(H!=undefined && W!=undefined) {
            w=W;
            h=H;
        }

        this.imageScale = w/this.faceImageHtml.width;

        //tryon canvas is defined in app.vue and is set to display-none
        this.canvas_div.width = w;
        this.canvas_div.height = h;
        this.ctx = this.canvas_div.getContext('2d', { willReadFrequently: true });
        this.ctx.drawImage(img,0,0,w,h);
        
    }

    line(context, p1,p2) {
        
        context.beginPath();
        context.moveTo(p1.x, p1.y);
        context.lineTo(p2.x , p2.y);
        
        context.lineWidth = 2;
        context.strokeStyle = '#FF3030';
        context.stroke();

    }
    arrow(context, p1,p2,key,value,offset=0, calltext=true) {
        context.beginPath();
        var headlen = 10; // length of head in pixels
   
        var dx = p2.x - p1.x;
        var dy = p2.y - p1.y;
        var angle = Math.atan2(dy, dx);

        context.moveTo(p1.x, p1.y);
        context.lineTo(p1.x + headlen * Math.cos(angle - Math.PI / 6), p1.y + headlen * Math.sin(angle - Math.PI / 6));
        context.moveTo(p1.x, p1.y);
        context.lineTo(p1.x + headlen * Math.cos(angle + Math.PI / 6), p1.y + headlen * Math.sin(angle + Math.PI / 6));

        context.moveTo(p1.x, p1.y);
        context.lineTo(p2.x, p2.y);
        context.lineTo(p2.x - headlen * Math.cos(angle - Math.PI / 6), p2.y - headlen * Math.sin(angle - Math.PI / 6));
        context.moveTo(p2.x, p2.y);
        context.lineTo(p2.x - headlen * Math.cos(angle + Math.PI / 6), p2.y - headlen * Math.sin(angle + Math.PI / 6));

        context.lineWidth = 2;
        context.strokeStyle = '#30FF30';
        context.stroke();

        if(calltext) this.text(context,p1,p2,key,value,offset)
      }

    circle(context,x,y,r){
        context.beginPath();
        context.arc(x, y, r, 0, 2 * Math.PI, false);
        context.lineWidth = 2;
        context.strokeStyle = '#FF3030';
        context.stroke();
    }

    text(ctx,p1,p2,key,value,offset=0){

        let p={y:p1.y-10,x:(p1.x+p2.x)/2};
        let str=value.toFixed()
        p.x=p.x+offset

        let dx=Math.abs(p1.x-p2.x)
        if(dx<70) 
            p.x=p.x+offset


        str=key+":"+str
        let fs = 14; //font size
        fs = fs + Math.floor((this.faceImageHtml.width/100) - 4)*2;
        // console.log(Math.floor((this.faceImageHtml.width/100) - 4));
        // console.log(fs);
        ctx.font = fs+"px arial";
        ctx.shadowColor = "rgba(0, 0, 0, 1.0)";
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 8;
        ctx.fillStyle = "#ffff00";
        ctx.textAlign = "center";
        ctx.fillText(str, p.x,p.y);
    }

    text2(ctx,x,y,key,value) {
        let str=value.toFixed()
        str=key+":"+str
        let fs = 14; //font size
        fs = fs + Math.floor((this.faceImageHtml.width/100) - 4)*2;
        ctx.font = fs+"px arial";
        ctx.shadowColor = "rgba(0, 0, 0, 1.0)";
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 8;
        ctx.fillStyle = "#ffff00";
        ctx.textAlign = "center";
        ctx.fillText(str, x, y);
    }

    async drawFacemarks(cnv) {
        // console.log("cnv.width = " + cnv.width + ", g_faceData.W = " + g_faceData.W);
        // console.log("cnv.height = " + cnv.height + ", g_faceData.H = " + g_faceData.H);
       
        cnv.width = g_faceData.W;
        cnv.height = g_faceData.H;
        let c2 = cnv.getContext('2d');
        c2.drawImage(this.faceImageHtml, 0, 0, cnv.width, cnv.height);

        let d=g_faceData;
        let dx,dy
        let p1,p2,p3
        //Face Center
        // console.log('d.Center', d.Center);
        this.line(c2,d.Center[0],d.Center[2]);
       
        //Left PD
        // console.log('d.LeftIris', d.LeftIris);
        dy=d.Center[1].y-d.LeftIris.center.y
        dx=d.Center[1].x-d.Center[0].x 
        p1=d.LeftIris.center;
        p2=copy(p1);p2.y+=dy;p2.x+=dx;

        this.line(c2,p1,p2);

        // this.circle(c2, p2.x-30, p2.y-30, 5);

        this.arrow(c2,d.Center[1],p2,"Left PD",d.LeftPD,+30, false);
        this.text2(c2, p2.x, p2.y-10, "Left PD", d.LeftPD);
    
        //Right PD
        // console.log('d.RightIris', d.RightIris);
        dy=d.Center[1].y-d.RightIris.center.y;
        dx=d.Center[1].x-d.Center[0].x 
        p1=d.RightIris.center;
        p2=copy(p1);p2.y+=dy;p2.x+=dx;
        
        this.line(c2,p1,p2);

        // this.circle(c2, p2.x-30, p2.y-30, 5);

        this.arrow(c2,d.Center[1],p2,"Right PD",d.RightPD,-30, false);
        this.text2(c2, p2.x, p2.y-10, "Right PD", d.RightPD);

        //Main PD
        dy=50;  
        p1=d.RightIris.center;    
        p3=copy(p1);p3.y+=dy
        this.line(c2,p1,p3); // right eye below

        p1=d.LeftIris.center;
        p2=copy(p1);p2.y+=dy;
        this.line(c2,p1,p2); // left eye below
          
        this.arrow(c2,p2,p3,"PD",d.PD)
   
        // Face Width
        // dy=-120;
        p1=d.Width[0];
        // p2=copy(p1);p2.y+=dy;
        p2=copy(p1);p2.y=d.Center[2].y;
        this.line(c2,p1,p2); // right eye top

        p1=d.Width[1];
        // p3=copy(p1);p3.y+=dy;
        p3=copy(p1);p3.y=d.Center[2].y;
        this.line(c2,p1,p3); // left eye top
        this.arrow(c2,p2,p3,"Face Width",d.FaceWidth);
    

        this.circle(c2,d.LeftIris.center.x,d.LeftIris.center.y,d.LeftIris.radius);
        this.circle(c2,d.RightIris.center.x,d.RightIris.center.y,d.RightIris.radius);

        return this.getCanvasBlob(cnv);
    }

    async fitGlasses(frame_img, frameData, tryonDragPosition={x: 0, y: 0, z: 0}) {
        if(!this.faceImageHtml || !frame_img) {
            return null;
        }

        let face_img = this.faceImageHtml;

        let fr = this.frameValues(frame_img);
        let fc = this.faceValues();

        //Get the dimensions for the higher resolution image
        let H,W;

        //Calculate frameScale
        //rough pixels per mm from pd
        let pd=63;
        if(g_face && g_faceData && g_faceData.PD) pd = g_faceData.PD;
        
        let frameWidth = 130;
        if(frameData.frame_width && !isNaN(frameData.frame_width)) frameWidth = parseInt(frameData.frame_width);
        else if(frameData.bridge_size && !isNaN(frameData.bridge_size) && frameData.lens_width && !isNaN(frameData.lens_width)) frameWidth = parseInt(frameData.bridge_size+frameData.lens_width*2+10);

        if(tryonDragPosition && tryonDragPosition.z) frameWidth += tryonDragPosition.z; // zoom in out frame

        let PixToMM_fc = fc.pd/pd;
        let PixToMM_fr = fr.w/frameWidth;

        let s=PixToMM_fc/PixToMM_fr;
        
        let sfc,sfr;


        sfc=1/s;
        sfr=1.1; // original value was 1.0, updated to 1.2 value to fit frame size on face, updated to 1.1
        W=face_img.width*sfc;
        H=face_img.height*sfc;

        this.initCanvasImage(face_img,W,H);

        const dx = fc.x*sfc-fr.x*sfr + (tryonDragPosition && tryonDragPosition.x ? tryonDragPosition.x : 0);
        const dy = fc.y*sfc-fr.y*sfr + (tryonDragPosition && tryonDragPosition.y ? tryonDragPosition.y : 0);
   
        const rotx=fc.x*sfc/sfr;
        const roty=fc.y*sfc/sfr;

        const fW=frame_img.width*sfr;
        const fH=frame_img.height*sfr;
    
        this.ctx.save();

        //Frame Rotation
        this.ctx.translate(rotx,roty);
        this.ctx.rotate(fc.theta);
        this.ctx.translate(-rotx,-roty);

        this.ctx.translate(dx,dy);
        
        this.ctx.imageSmoothingEnabled = false;
        this.ctx.webkitImageSmoothingEnabled = false;
        this.ctx.drawImage(frame_img, 0, 0, fW, fH);
        
        this.ctx.restore();

        return this.getCanvasBlob(this.canvas_div);
    }

    getCanvasBlob(canvas) {
        return new Promise(function(resolve) {
          canvas.toBlob(function(blob) {
            resolve(blob)
          })
        })
    } 

    averageFeatures(d,a,b) {
        var X=0;
        var Y=0;
        for(let k=a-1;k<b;k++)
        {
            X=X+d[k]._x;
            Y=Y+d[k]._y;
        }
        X=X/(b-a+1);
        Y=Y/(b-a+1);

        var r = {x:X,y:Y};
        return r;
    }

    faceValues() {
       
        let d=g_faceData;
        var eye_l={
            x:d.LeftIris.center.x,
            y:d.LeftIris.center.y
        }

        var eye_r={
            x:d.RightIris.center.x,
            y:d.RightIris.center.y
        }
        
        var pd = eye_l.x - eye_r.x;

        // var hh=6;

        var x = (eye_l.x+eye_r.x)/2;
        var y = (eye_l.y+eye_r.y)/2;
        var dx = eye_l.x-eye_r.x;
        var dy = eye_l.y-eye_r.y;

        var theta = Math.atan2(dy,dx);

        //The algo gives a sightly low x value. So put a bias:
        x=x+pd*0.03;
        //The algo gives a sightly low y value. So put a bias:
        y=y-pd*0.1;

        return {x:x,y:y,pd:pd,theta:theta,eyelx:eye_l.x,eyely:eye_l.y,eyerx:eye_r.x,eyery:eye_r.y};
    }

    alpha(x,y,data) {
        const k = x*4+3 +(this.canvas_div.width*4)*y;
        return data[k]
    }

    frameValues(img) {

        //find the size and center of glasses
        this.initCanvasImage(img)
    
        const W=img.width;
        const H=img.height;
        const data = this.ctx.getImageData(0, 0, W,H).data;

        this.ctx.fillStyle = "#FF0000"
        let maxx=0;
        let maxy=0;
        let minx=W;
        let miny=H;

        let x=0;
        let y=0;
        let a=0;
        const dy = Math.floor(H/40);
        //Find left limit of frame
        for(y=Math.floor(H/4);y<H*3/4;y=y+dy)
        for(x=0;x<W/2;x++)
        {
            a=this.alpha(x,y,data);
            //debug this.ctx.fillRect(x,y,1,1);
            if(a>10){
                minx=Math.min(minx,x);
                //debug this.ctx.fillRect(x,y,4,4);
                break;
            }
        }
        //Find right limit of frame
        for(y=Math.floor(H/4);y<H*3/4;y=y+dy)
        for(x=W;x>W/2;x--)
        {
            a=this.alpha(x,y,data)
            //debug this.ctx.fillRect(x,y,1,1);
            if(a>10){
                maxx=Math.max(maxx,x);
                //debug this.ctx.fillRect(x,y,4,4);
                break;
            }
        }

        x=(maxx+minx)/2;
        let start = false;
        for(y=Math.floor(H/4);y<H*3/4;y++)
        {
            let a=this.alpha(x,y,data);
            if(!start)
            {
                if(a>10)
                {   
                    start=true;
                    miny=y;
                }
            }
            else{
                if(a<10)
                    {   
                        maxy=y;
                        break;
                    }
            }
        }

        y=(maxy+miny)/2;
        y=maxy;
        let w=maxx-minx;

        //debug
        //this.debugFrame(minx,maxx,x,y);
        return {x:x,y:y,w:w,h:H}
    }

        debugFrame(minx,maxx,x,y){
            this.ctx.fillStyle = "#FF"
            this.drawX(x,y);
            this.drawX(minx,y);
            this.drawX(maxx,y);
        }
        debugFace(fc){
            this.ctx.fillStyle = "#FFF000"
            this.drawX(fc.x,fc.y);
            this.drawX(fc.eyelx,fc.eyely);
            this.drawX(fc.eyerx,fc.eyery);
        }

        drawX(x,y) {
             this.ctx.fillRect(x,y-40,1,80);
             this.ctx.fillRect(x-40,y,80,1);
        }

        // functions from drawing_utils
        x(a) {
            a = a || {};
            return Object.assign(Object.assign(Object.assign({}, this.w), { fillColor: a.color }), a);
        }

        y(a, c) {
            return a instanceof Function ? a(c) : a;
        }
}

export default TryonLib;


//=======================================================

var CIRCLEFIT = (function () {
    var my = {},
        points = [];
  
    function linearSolve2x2(matrix, vector) {
      var det = matrix[0]*matrix[3] - matrix[1]*matrix[2];
      if (det < 1e-8) return false; //no solution
      var y = (matrix[0]*vector[1] - matrix[2]*vector[0])/det;
      var x = (vector[0] - matrix[1]*y)/matrix[0];
      return [x,y];
    }
  
    my.addPoint = function (x, y) {
      points.push({x: x, y: y});
    }
  
    my.resetPoints = function () {
      points = [];
    }
  
    my.compute = function () {
      var result = {
        points: points,
        projections: [],
        distances: [],
        success: false,
        center: {x:0, y:0},
        radius: 0,
        residue: 0,
        computationTime: performance.now()
      };
  
      //means
      var m = points.reduce(function(p, c) {
        return {x: p.x + c.x/points.length,
                y: p.y + c.y/points.length};
      },{x:0, y:0});
      
      //centered points
      var u = points.map(function(e){
        return {x: e.x - m.x,
                y: e.y - m.y};
      });
  
      //solve linear equation
      var Sxx = u.reduce(function(p,c) {
        return p + c.x*c.x;
      },0);
  
      var Sxy = u.reduce(function(p,c) {
        return p + c.x*c.y;
      },0);
  
      var Syy = u.reduce(function(p,c) {
        return p + c.y*c.y;
      },0);
  
      var v1 = u.reduce(function(p,c) {
        return p + 0.5*(c.x*c.x*c.x + c.x*c.y*c.y);
      },0);
  
      var v2 = u.reduce(function(p,c) {
        return p + 0.5*(c.y*c.y*c.y + c.x*c.x*c.y);
      },0);
  
      var sol = linearSolve2x2([Sxx, Sxy, Sxy, Syy], [v1, v2]);
  
      if (sol === false) {
        //not enough points or points are colinears
        return result;
      }
  
      result.success = true;
  
      //compute radius from circle equation
      var radius2 = sol[0]*sol[0] + sol[1]*sol[1] + (Sxx+Syy)/points.length;
      result.radius = Math.sqrt(radius2);
  
      result.center.x = sol[0] + m.x;
      result.center.y = sol[1] + m.y;
  
      points.forEach(function(p) {
        var v = {x: p.x - result.center.x, y: p.y - result.center.y};
        var len2 = v.x*v.x + v.y*v.y;
        result.residue += radius2 - len2;
        var len = Math.sqrt(len2);
        result.distances.push(len - result.radius);
        result.projections.push({
          x: result.center.x + v.x*result.radius/len,
          y: result.center.y + v.y*result.radius/len
        });     
      });
  
      result.computationTime = performance.now() - result.computationTime;
  
      return result;
    }
  
    return my;
  }());
 
  
import store from './index.js'

class initStore {
    init() {
      // let shopCode = "gulzarioptics";
      // let shopCode = "free1";
      store.commit('setProductsCategoryExtendedInfo', {});
      store.commit('setProductsTryonImages', {});

      if(['localhost', '127.0.0.1'].indexOf(location.hostname) > -1) {
        // for running on localhost
        store.commit('setShopCode', "gulzarioptics");
        store.commit('setURL', 'https://gulzarioptics.testspace.click');
        store.commit('setS3Url', 'https://gulzarioptics.testspace.click');
      } else if(location.hostname == 'gulzarioptics.tarospace.local') {
        // for running on asad local system
        store.commit('setShopCode', "gulzarioptics");
        store.commit('setURL', 'http://api.tarospace.local');
        store.commit('setS3Url', 'https://s3.us-east-1.amazonaws.com/shop.testspace.click/gulzarioptics');
      } else {
        store.commit('setURL', location.origin);
        store.commit('setS3Url', location.origin);
      }

      // let si = window.shop_info;
      // if(!si && (location.hostname.includes('tarospace.local') || location.hostname.includes('localhost'))) {
      //   window.shop_info = {shopcode: shopCode, domain: 'testspace'};
      //   si = window.shop_info;
      // }
      // store.commit('setShopCode', si.shopcode);
      // store.commit('setURL', si.domain == 'testspace' ? 'testspace.click' : 'tarospace.com');

      // let u = store.state.url;
      // if(u == 'tarospace.local' || u == 'localhost') u = 'testspace.click';
      // store.commit('setS3Url', 'https://s3.us-east-1.amazonaws.com/shop.'+u+'/');

      // store.commit('setIsTryonSelected', '');
      // store.commit('setUserLoadedImageObject', ''); // fix this later to save blob url
      // store.commit('setTryOnSwitch', false);
      store.commit('setShowTryonModal', false);
      store.commit('setInitTryOn', false);

      // submenu and filters store vars
      store.commit('setShowSubMenu', false);
      store.commit('setShowMobileFilters', false);

      store.commit('setFilterByParams', {FilterBy: {},  per_page: 20, page: 1, tags_count: 'yes'});
    }
}

export default initStore;
<template>
  <transition name="slide">
    <ul class="mobile_menu menu-dropdown" v-if="show">
      <li v-if="$store.state.sales_manager_token">
     
        <button style="background-color: #02549e !important;" class="primary-button align-items-center justify-content-center d-flex w-100" @click="admin_Router">Admin</button>
    
      </li>
      <li v-if="$store.state.loggedInUser">
        <div class="d-flex">
          <div class="mobile_menu_button" style="width: 60% !important;" @click="clicked({link: '/dashboard'})">Dashboard</div>
        
        <button class="primary-button align-items-center justify-content-center d-flex" @click="logout">Logout</button>
    
        </div>
      </li>
      <li v-else>
        <div class="d-flex align-items-center justify-content-center">
        <button @click="redirectToDashboard('signin')" class="primary-button align-items-center justify-content-center d-flex">Login</button>
        <button class="primary-button align-items-center justify-content-center d-flex" @click="redirectToDashboard('signup')">Sign Up</button>
      </div>
      </li>
      <li>
        <div class="mobile_menu_button" @click="clicked({link: '/favourites'})">Favourites</div>
      </li>
      <li
        v-for="item in $store.state.headerFilterTree"
        :key="item"
        @click="
          clicked(item);
          toggleHeaderItems();
        "
      >
        <div
          class="mobile_menu_button"
          @click="clicked(item)"
          v-if="isVisible(item)"
        >
          {{ item.label }}
        </div>
        <ul
          class="mobile_menu_content"
          v-if="isVisible(item) && item.children && item.children.length"
        >
          <li v-for="entry in item.children" :key="entry">
            <div
              class="mobile_menu_button"
              @click="clicked(entry)"
              v-if="isVisible(entry)"
            >
              {{ entry.label }}
            </div>
          </li>
        </ul>
      </li>
    
     
     
      <!-- <li>
        <div class="mobile_menu_button">
          <search style="position: unset; width: 95%;" />
        </div>
      </li> -->
      <li class="py-2" style="display: flex;">
        <div class="dropdown my-auto">
          <label
            style="padding: 10px; width: 100%; font-size: 14px;"
            class="dropdown_button my-auto"
            @click="top_clicked"
          >
            {{ this.$store.state.shopCurrency.toUpperCase() }}
            <fa icon="chevron-down" />
          </label>
          <div class="dropdown-content menu-dropdown" style="position: relative; width: 100%">
            <div>
              <a class="menu-dropdown-item"
                @click="changeCurrency(defaultCurrency)"
                >{{ defaultCurrency }}</a
              >
            </div>
            <div>
              <a class="menu-dropdown-item" @click="changeCurrency('USD')">USD</a>
            </div>
          </div>
        </div>
      </li>
    
    </ul>
  </transition>
</template>

<script>
// import search from "@/components/navigate/search.vue";
// import icon_bar from "../../components/navigate/header_icon_bar.vue";
export default {
  components: {
    // search,
    // icon_bar
  },
  props: {
    show: { type: Boolean, default: false },
    menuList: { type: Array },
  },
  data() {
    return {};
  },
  computed: {
    defaultCurrency() {
      if(this.$store.state.shopInfo) return this.$store.state.shopInfo.default_currency;
      return '$';
    }
  },

  methods: {
    changeCurrency(c) {
      this.$store.commit("setShopCurrency", c);
    },
    redirectToDashboard(action) {
        if (action === 'signin') {
			this.$store.commit('setShowSignUp', false);
      this.$store.commit("setShowSubMenu", !this.$store.state.showSubMenu);
            this.$router.push({ path: '/dashboard' });
        } else if (action === 'signup') {
			this.$store.commit('setShowSignUp', true);
      this.$store.commit("setShowSubMenu", !this.$store.state.showSubMenu);

            this.$router.push({ path: '/dashboard' });
        }
    },
    admin_Router() {
            //     this.$router.push({path: '/admin/index.html'});
            //     setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // location.href = "/admin/index.html?v="+this.$store.state.sales_manager_token;
            
            window.location = '/admin/dashboard.html';
        },
    clicked(item) {
      if(item == 'logout') {
        this.$store.commit("setShowSubMenu", false);
        this.$store.dispatch("logout", this.$store.state.customer_token);
      } else {
        if(!item.children) {
          // this.$emit("subMenuClick",item)
          this.$store.commit("setShowSubMenu", false);
          if(item.link === '/')  location.href = location.origin;
          else this.$router.push({ path: item.link ?? item.filter });
          // this.$store.commit('setShowSubMenu', !this.$store.state.showSubMenu);
        }
      }
    },
    toggleHeaderItems() {
      // this.$emit("subMenuClick",item)
      // this.$store.commit('setShowSubMenu', !this.$store.state.showSubMenu);
    },
    isVisible(n) {
      if (n.visible === false) return false;

      return true;
    },
    logout() {
      this.$store.dispatch("logout", this.$store.state.customer_token);
      this.$store.commit("setShowSubMenu", !this.$store.state.showSubMenu);
    },
  },
};
</script>
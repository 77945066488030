<template>
  <div class="nav-icon-bar" style="align-items: center;">
    <div class="header-icon" title="Favourites" v-if="!this.$isMobile()">
      <div class="item-count" v-if="$store.state.favItems.length > 0">
        {{ $store.state.favItems.length }}
      </div>

      <fa icon="heart" style="font-size: 22px; padding-top: 8px;" @click="favourites()" />
      <!-- <svg
        width="24"
        height="24"
        viewBox="0 0 29 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="favourites()"
      >
        <path
          d="M14.5399 26C13.8739 26.0026 13.234 25.729 12.7602 25.2392L2.34511 14.3387C1.57579 13.5313 0.971429 12.5685 0.568777 11.5089C0.166125 10.4494 -0.0264012 9.31519 0.00290759 8.17531C0.0322164 7.03544 0.282747 5.91372 0.739264 4.87837C1.19578 3.84301 1.84874 2.91566 2.65845 2.15271C3.43874 1.42367 4.35143 0.866721 5.34199 0.515135C6.33256 0.163548 7.38068 0.0245372 8.42372 0.106407C10.509 0.251006 12.4731 1.18328 13.9509 2.72987L14.5399 3.34638L15.3797 2.4544C16.1511 1.64923 17.071 1.0167 18.0834 0.595284C19.0958 0.173867 20.1795 -0.0276316 21.2686 0.00304309C22.3577 0.0337178 23.4295 0.295924 24.4187 0.773717C25.408 1.25151 26.294 1.9349 27.023 2.78234C27.7196 3.59899 28.2517 4.55422 28.5877 5.59095C28.9236 6.62768 29.0564 7.72464 28.9782 8.8163C28.84 10.9987 27.9493 13.0544 26.4716 14.601L16.3071 25.2392C16.0752 25.4819 15.7996 25.6741 15.4963 25.8047C15.193 25.9353 14.8679 26.0017 14.5399 26ZM7.89733 3.24144C6.71315 3.22441 5.56518 3.66854 4.67629 4.48759C4.16819 4.9635 3.75819 5.54289 3.47137 6.19032C3.18455 6.83775 3.02693 7.53961 3.00816 8.25298C2.98939 8.96634 3.10986 9.67622 3.36221 10.3392C3.61455 11.0022 3.99346 11.6043 4.47576 12.1087L14.5399 22.6419L24.3409 12.3842C26.3337 10.2854 26.5342 6.92741 24.7921 4.89422C24.3374 4.36245 23.7838 3.93334 23.1652 3.63315C22.5466 3.33296 21.876 3.168 21.1944 3.14835C20.5128 3.12871 19.8345 3.25479 19.2011 3.5189C18.5676 3.783 17.9923 4.17957 17.5103 4.68435L15.6053 6.67818C15.4655 6.82492 15.2995 6.94136 15.1167 7.0208C14.9339 7.10025 14.7379 7.14115 14.5399 7.14115C14.342 7.14115 14.146 7.10025 13.9632 7.0208C13.7804 6.94136 13.6143 6.82492 13.4746 6.67818L11.8202 4.95981C11.3067 4.41706 10.6958 3.98585 10.0226 3.69096C9.34939 3.39607 8.62713 3.2433 7.89733 3.24144Z"
          fill="white"
        />
      </svg> -->
    </div>

    <div class="header-icon  dsmnone" title="Cart">
      <div class="item-count" v-if="$store.state.cartItems.length > 0">
        {{ $store.state.cartItems.length }}
      </div>

      <svg
       @click="cart() "
        width="28"
        height="28"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.31 11.242A1 1 0 0 1 3.28 10h17.44a1 1 0 0 1 .97 1.242l-1.811 7.243A2 2 0 0 1 17.939 20H6.061a2 2 0 0 1-1.94-1.515L2.31 11.243z"
        ></path>

        <path d="M9 14v2"></path>

        <path d="M15 14v2"></path>

        <path d="m6 10 4-6"></path>

        <path d="m18 10-4-6"></path>
      </svg>
    </div>

    <!-- <div class="header-icon" v-if="smallScreenIcons || this.$isMobile()">
      <fa icon="bars" @click.prevent.stop="toggleSubmenu()" />
    </div> -->

    <!-- <div
      class="header-icon dsmnone"
      v-if="$store.state.customer_token && !$store.state.sales_manager_token && !this.$isMobile()"
      title="Logout"
    >
      <fa icon="sign-out-alt" style="font-size: 22px;" @click.prevent.stop="logout()" />
    </div> -->

    <div class="dropdown dsmnone" style="padding: 10px;" v-if="!this.$isMobile()">
      <label class="dropdown_button" style="font-size: 14px;" @click="top_clicked">{{this.$store.state.shopCurrency.toUpperCase()}}
        <fa icon="chevron-down" />
      </label>
      <div class="dropdown-content menu-dropdown">
        <div>
          <a class="menu-dropdown-item" @click="changeCurrency(defaultCurrency)">{{ defaultCurrency }}</a>
        </div>
        <div>
          <a class="menu-dropdown-item" @click="changeCurrency('USD')">USD</a>
        </div>
      </div>
    </div>

    <Submenu :show="$store.state.showSubMenu" @subMenuClick="subMenuClick" />
  </div>
</template>

<script>

import Submenu from "./submenu.vue";

export default {
  components: {
    Submenu,
  },
  data() {
    return {
      showSearch: false,
    };
  },
  mounted () {
    // console.log('Vue => ', this.$store.state)
  },
  computed: {
    defaultCurrency() {
      if(this.$store.state.shopInfo) return this.$store.state.shopInfo.default_currency;
      return '$';
    }
  },
  methods: {
    changeCurrency(c) {
      this.$store.commit('setShopCurrency', c);
    },
    subMenuClick() {
      this.$store.commit("setShowSubMenu", false);
    },
    search() {
      this.showSearch = !this.showSearch;
    },
    user() {
      this.$router.push({
        path: "/dashboard",
      });
    },
    favourites() {
      this.$router.push({
        path: "/favourites",
      });
    },
    cart() {
      this.$router.push({
        path: "/cart",
      });
    },
    logout() {
      this.$store.dispatch("logout", this.$store.state.customer_token);
    },
  }
};
</script>
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import ServerInterface from '@/interface/be_interface.js';
import router from '@/router/index.js'

export default createStore({
  state:  {
      highlightColor: "#1F5591",
      url: '',
      s3Url: '',
      sales_manager_token: '',
      customer_token: '',
      sales_manager_name:'',
      customer_id: '',
      loggedInUser: null,
      customerDetails: null,
      guestDetails: null,
      shopCode: '',
      shopInfo: null,
      shopUSDRate: 1,
      shopCurrency: '',
      showSignUp:false,
      userDataLabel: '',
      chatIDE:"",
      shopFilterDefs: null,
      banners : {'title':'Latest Selection', list: {}},
      productCategories :{
        title: 'Product Categories', list: []
      },
      headerFilterTree: [],
      // productsList : [],
      productsCategoryExtendedInfo: {},
      productsCount : 0,
      productDetails :{},
      tags_count :{},
      filterBy: null,
      favItems: [],
      cartItems: [],
      checkoutProcedures: [],
      vadItem: {productId: '', productShop: ''}, // cart item selected for checkout procedure to add userdata 
      countriesList: [],
      addressesList: [],
      paymetMethodList: [],
      userPrescList: [],
      // userOrderList: [],
      posCustomers: [],
      // face_b64: '{}',
      IsTryonSelected: '',
      showTryonModal: '',
      tryOnSwitch: false,
      initTryOn: false,
      userLoadedImageObject: null,
      showSubMenu: false,
      showMobileFilters: false,
      orderTemplateLoaded: false,
      orderResponseView: false,
      modalActionMode: false,
      tryonPosition: {x: 0, y: 0, z: 0},
      productsTryonImages: {},
      TryonActive: {},
      PosRoles: ['shop_admin', 'customer_manager']
  },
  plugins: [createPersistedState()],
  getters: {
    defaultCurrency(state) {
      if(state.shopInfo) return state.shopInfo.default_currency_symbol;
      else return '$';
    },
    getShopLogo(state) {
      return state.s3Url+`/shop_landing_page/shop_logo.png`;
    },
    getShopPrimaryLogo(state) {
      return state.s3Url+`/shop_landing_page/shop_primary_logo.png`;
    },
    getImageUrl: (state) => (method,imageName,otherParams={},tn=false) => {
      let url = "";
      if(imageName.includes(`_product_files/${otherParams.productId}`)) {
        url = imageName;
        if(tn) {
          if(otherParams?.image_type == "virtual_room") url = url.replace(".png", "_tn.png");
          else url = url.replace(".png", "_tn.jpg");
        }
      } else {
        let sc = state.shopCode;
        if(otherParams.shopCode) sc = otherParams.shopCode;
        let s = new ServerInterface(state.url, sc);
        let params = imageName ? {'image':imageName} : {};
        params = {...params, ...otherParams};
  
        url = state.url + '/interface/index.php?json='+s.json(method,params);
  
        if(location.hostname.includes('tarospace.local')) {
          url = 'http://api.tarospace.local/interface/index.php?json='+s.json(method,params);
        }
        
        if(method == 'products.downloadProductPicture' || method == "tagfiles") {
          if(state.s3Url.includes('s3.us-east-1.amazonaws.com')) {
            // for running on A.U local system
            url = `${state.s3Url}/_product_files/${otherParams.productId}/${imageName}`;
          } else {
            url = `${state.s3Url}/${otherParams.shopCode}/product_files/${otherParams.productId}/${imageName}`;
          }
        }
      }
      return url;
    },
    getS3HtmlUrl: (state) => (name) => {
      if(state.s3Url.includes('s3.us-east-1.amazonaws.com')) {
        // for running on asad local system
        return state.s3Url + '/' + name + '.html';
      } else {
        return state.s3Url + '/shop-' + name + '.html';
      }
    },
    getOrderParams: (state) => (params) => {
      let orderParams = params;
      
      orderParams.orderItems = [];
      
      if(state.cartItems.length) {
        state.cartItems.forEach(e => {
          if(e.price) e.unit_price = e.price;
          else if(e.base_price) e.unit_price = e.base_price;
          let a = {
            title: e.title,
            productCode: e.product_code,
            unit_price: e.base_price ?? e.price,
            quantity: e.quantity,
            shopCode: e.shopCode,
            discount: e.discount,
            checkVAr:"Hello",
            user_data: JSON.stringify([typeof e.userdata == 'string' ? JSON.parse(e.userdata) : e.userdata])
          }
          a.unit_price = a.unit_price ?? '0.00';
          a.discount = a.discount ?? '0.00';
          orderParams.orderItems.push(a);
        });
      }

      orderParams.sessionRequested = false;
      for(let i in orderParams.transactions) {
        if(orderParams.transactions[i].transactionType.toLowerCase() == 'credit card') orderParams.sessionRequested = true;
      }

      // delete orderParams.total;
      // delete orderParams.shipComp;
      // delete orderParams.shipCost;

      return orderParams;
    },
    productFiltersUri: () => (params, type="e") => {
      if(type == 'd') {
        let p = null;
        if(params) {
          try {
            p = JSON.parse(decodeURIComponent(params));
          } catch(ex) {
            p = null;
          }
        }
        return p;
        
      } else return encodeURIComponent(JSON.stringify(params));
    },   
    validateOrderParams: (state) => (params) => {
      if(!state.customer_token && !state.guestDetails) {
        return {
          status: false,
          message: 'Please login to process order',
          title: 'Error'
        };
      }
      if(!state.cartItems.length) {
        return {
          status: false,
          message: 'Please add some items in cart',
          title: 'Cart Empty'
        };
      }
      
      if(!state.customer_token) {
        if(!state.guestDetails.email) {
          return {
            status: false,
            message: 'Please fill EMAIL ADDRESS',
            title: 'Missing Email Address'
          };
        }
        if(!state.guestDetails.phone) {
          return {
            status: false,
            message: 'Please fill PHONE',
            title: 'Missing Phone'
          };
        }
        if(!state.guestDetails.first_name) {
          return {
            status: false,
            // message: 'Please fill FIRST NAME',
            message: 'Please fill address form',
            title: 'Incomplete Name'
          };
        }
        if(!state.guestDetails.last_name) {
          return {
            status: false,
            // message: 'Please fill LAST NAME',
            message: 'Please fill address form ' ,
            title: 'Incomplete Name'
          };
        }
        if(!state.guestDetails.b_email && !state.guestDetails.sameAsShipping) {
          return {
            status: false,
            message: 'Please fill billing EMAIL ADDRESS',
            title: 'Missing Email Address'
          };
        }
        if(!state.guestDetails.b_phone  && !state.guestDetails.sameAsShipping) {
          return {
            status: false,
            message: 'Please fill billing PHONE',
            title: 'Missing Phone'
          };
        }
        if(!state.guestDetails.b_first_name  && !state.guestDetails.sameAsShipping) {
          return {
            status: false,
            // message: 'Please fill FIRST NAME',
            message: 'Please fill billing address form',
            title: 'Incomplete Name'
          };
        }
        if(!state.guestDetails.b_last_name  && !state.guestDetails.sameAsShipping) {
          return {
            status: false,
            // message: 'Please fill LAST NAME',
            message: 'Please fill billing address form ',
            title: 'Incomplete Name'
          };
        }

        let vadd = true;
        if(params?.isPickup) vadd = false;
        
        if(vadd) {
          if(!state.guestDetails.street_addr) {
            return {
              status: false,
              // message: 'Please fill STREET ADDRESS',
              message: 'Please fill address form ',
              title: 'Incomplete Address'
            };
          }
          if(!state.guestDetails.city) {
            return {
              status: false,
              // message: 'Please fill TOWN, CITY',
              message: 'Please fill address form',
              title: 'Incomplete Address'
            };
          }
          if(!state.guestDetails.province) {
            return {
              status: false,
              // message: 'Please fill COUNTY/Province',
              message: 'Please fill address form',
              title: 'Incomplete Address'
            };
          }
          if(!state.guestDetails.country || state.guestDetails.country == "countryplace") {
            return {
              status: false,
              // message: 'Please fill COUNTRY',
              message: 'Please fill address form ',
              title: 'Incomplete Address'
            };
          }
          if(!state.guestDetails.postal_code) {
            return {
              status: false,
              // message: 'Please fill POSTCODE',
              message: 'Please fill address form',
              title: 'Incomplete Address'
            };
          }
          if(!state.guestDetails.b_street_addr  && !state.guestDetails.sameAsShipping) {
            return {
              status: false,
              // message: 'Please fill STREET ADDRESS',
              message: 'Please fill address form',
              title: 'Incomplete Address'
            };
          }
          if(!state.guestDetails.b_city  && !state.guestDetails.sameAsShipping) {
            return {
              status: false,
              // message: 'Please fill TOWN, CITY',
              message: 'Please fill address form',
              title: 'Incomplete Address'
            };
          }
          if(!state.guestDetails.b_province && !state.guestDetails.sameAsShipping) {
            return {
              status: false,
              // message: 'Please fill COUNTY/Province',
              message: 'Please fill address form ',
              title: 'Incomplete Address'
            };
          }
          if(!state.guestDetails.b_postal_code  && !state.guestDetails.sameAsShipping) {
            return {
              status: false,
              // message: 'Please fill POSTCODE',
              message: 'Please fill address form',
              title: 'Incomplete Address'
            };
          }
        }
        if(!params.shippingInfo || !params.shippingInfo.length) {
          return {
            status: false,
            message: 'Please select shipping method',
            title: 'Missing Address'
          };
        }
      } else {
        if(!params.shippingInfo || !params.shippingInfo.length) {
          return {
            status: false,
            message: 'Please select shipping address',
            title: 'Missing Address'
          };
        }
      }
      if(!params.shippingInfo[0].shippingCompId) {
        return {
          status: false,
          message: 'Please select shipping method',
          title: 'Missing Shipping'
        };
      }
      // if(params.shippingInfo[0].shippingRef && params.shippingInfo[0].shippingRef[0] == '{' && params.shippingInfo[0].shippingRef[params.shippingInfo[0].shippingRef.length-1] == '}' && !JSON.parse(params.shippingInfo[0].shippingRef).id) {
      //   return {
      //     status: false,
      //     message: 'Please select pickup branch',
      //     title: 'Missing Pickup Branch'
      //   };
      // }
      if(params?.isPickup && !params.shippingInfo[0].shippingRef) {
        return {
          status: false,
          message: 'Please select pickup branch',
          title: 'Missing Pickup Branch'
        };
      }

      // validate transactions
      if(!params.transactions || !params.transactions.length || (params.transactions.filter(x => x.transactionType.toLowerCase() != "gift card").length == 0 && params.transactions.map(x => {return x.amount}).reduce((a, b) => {return a+b}) != params.total)) {
        return {
          status: false,
          message: 'Please select payment method',
          title: 'Missing Payment'
        };
      }

      let tpaid = 0;
      let validate_paym = true;
      for(let i in params.transactions) {
        if(params.transactions[i].transactionType.toLowerCase() == 'cod' || params.transactions[i].transactionType.toLowerCase() == 'cash on delivery') validate_paym = false;
        tpaid += parseFloat(params.transactions[i].amount);
      }
      
      if(tpaid < params.total && validate_paym) {
        return {
          status: false,
          message: 'Please select another payment method to complete',
          title: 'Error'
        };
      }

      return {
        status: true,
        message: ''
      };
    },
    imgPathFromShopCat(state) {
      return `/assets/business_categories/${state.shopInfo ? state.shopInfo.business_category : ''}/`;
    },
    isTryOnCategory(state) {
      if(state.shopInfo) return ['optics'].indexOf(state.shopInfo.business_category) > -1 ? true : false;
      return false;
    },
    bus_cat_vad_label(state) {
      if(state.shopInfo.business_category == 'optics') return "Lens";
      return "";
    },
    product_applied_filters: (state) => (temp_route) => {
      // let temp_route = router.currentRoute._value;
      // console.log(temp_route);
      temp_route = temp_route.currentRoute._value;
      if(temp_route.name == "Products") {
        let pageNo = temp_route.query?.page ? parseInt(temp_route.query.page) : 1;

        let p = {
          featured: '',
          SearchBy: '',
          FilterBy: '',
          latest: '',
          isActive: 'active',
          catCode: '',
          model: '',
          price_min: '',
          price_max: '',
          tryon: '',
          sale: '',
          page: pageNo,
          per_page: 20,
          tags_count: "yes"
        };

        if (temp_route.params) {
          if(temp_route.params.cat == 'search') {
            p.SearchBy = temp_route.query.filterBy;
            p.catCode = "";
          } else {
            if(temp_route.params.cat) {
              let urlParams = decodeURIComponent(temp_route.params.cat);
              let j = {};
              try {
                if (urlParams[0] == '{' && urlParams[urlParams.length - 1] == '}') {
                  // url params is a JSON
                  if(urlParams.catCode == 'all') urlParams.catCode = '';
                  j = JSON.parse(urlParams);
                } else {
                  let ind = state.shopFilterDefs.findIndex(x => x.name.toLowerCase() == urlParams.toLowerCase());
                  if (ind > -1) {
                    j = JSON.parse(decodeURIComponent(state.shopFilterDefs[ind].value));
                  } else {
                    ind = state.productCategories.list.findIndex(x => x.label.toLowerCase().replace(' ', '_') == temp_route.params.cat);
                    if (ind > -1) j = {catCode: state.productCategories.list[ind].code};
                  }
                }
                p = {...p, ...j};
              } catch (e) {
                console.log(e);
              }
            }

            // set sort by property of filter search object
            if(temp_route.query?.sortby) p.sortby = temp_route.query.sortby;

            if(temp_route.query?.filterBy) {
              let urlParams = decodeURIComponent(temp_route.query.filterBy);
              let j = {};
              try {
                if (urlParams[0] == '{' && urlParams[urlParams.length - 1] == '}') {
                  // url params is a JSON
                  j = JSON.parse(urlParams);
                } else {
                  let ind = state.shopFilterDefs.findIndex(x => x.name.toLowerCase() == urlParams.toLowerCase());
                  if (ind > -1) {
                    j = JSON.parse(decodeURIComponent(state.shopFilterDefs[ind].value));
                  }
                }
                p = {...p, ...j};
              } catch (e) {
                console.log(e);
              }
            }
          }
        }
        return p;
      }
      return false;
    }
  },
  mutations: {
    setURL(state, u) {state.url = u;},
    setS3Url(state, u) {state.s3Url = u;},
    setShopCode(state, code) {state.shopCode = code;},
    setLoggedInUser(state, info) {state.loggedInUser = info;},
    setCustomerDetails(state, info) {state.customerDetails = info;},
    setGuestDetails(state, info) {
      // console.log("Modifying guestDetails:", info);
      state.guestDetails = info;
    },
    setShopDetails(state, info) {state.shopInfo = info;},
    setUserDataLabel(state, value) {state.userDataLabel = value;},
    setChatIDE(state, value) {state.chatIDE = value;},
    setShopFilterDefs(state, info) {state.shopFilterDefs = info;},
    setBanners(state, banners) {state.banners['list'] = banners;},
    setProductCategories(state, productCategories) {state.productCategories['list'] = productCategories["list"];},
    // setProducts(state, productsList) {state.productsList = productsList;},
    setProductsCategoryExtendedInfo(state, list) { state.productsCategoryExtendedInfo = list; },
    setProductsCount(state, value) {state.productsCount = value;},
    setTagCounts(state, tags_count) {state.tags_count = tags_count;},
    setHeaderFilters(state, flt) {state.headerFilterTree = flt;},
    setFilterByParams(state, fltBy) {state.filterBy = {...state.filterBy, ...fltBy};},
    setProductDetails(state, details) {state.productDetails = details;},
    setCartItems(state, items) {
      state.cartItems = items;
      
      sessionStorage.setItem('sessionItems', JSON.stringify({
        fav: state.favItems.map(x => {return {id: x.id, shopCode: x.shopCode}})
      }));
    },
    updateCartItemComment(state, { index, comment }) {
      const commentValue = Object.values(comment)[0]; 
      state.cartItems[index].comment = commentValue;
    },
    removeCartItems(state, items) {
      state.cartItems.splice(items, 1); // items is cart array index
    },
    setCheckoutProcedures(state, value) {state.checkoutProcedures = value},
    setVadItem(state, item) {state.vadItem = item;},
    setCountriesList(state, list) {state.countriesList = list;},
    setAddressesList(state, list) {state.addressesList = list},
    setFavItems(state, list) {
      state.favItems = list;

      sessionStorage.setItem('sessionItems', JSON.stringify({
        fav: state.favItems.map(x => { return {id: x.id, shopCode: x.shopCode} })
      }));
    },
    setHighlightColor(state, color) {state.highlightColor = color},
    setOrderList(state, list) {state.userOrderList = list},
    setPresList(state, list) {state.userPrescList = list},
    setCustomerId(state, id) {state.customer_id = id},
    setToken(state, token) {state.customer_token = token},
    setShopCurrency(state, val) {state.shopCurrency = val},
    setShopUSDRate(state, val) {state.shopUSDRate = val},
    setPOSToken(state, token) {state.sales_manager_token = token},
    setPOSName(state, name) {state.sales_manager_name = name},
    setPosCustomers(state, list) {state.posCustomers = list},
    // setFaceB64(state, value) {state.face_b64 = value},
    setIsTryonSelected(state, value) {state.IsTryonSelected = value},
    setShowTryonModal(state, value) {state.showTryonModal = value},
    setTryOnSwitch(state, value) {state.tryOnSwitch = value},
    setInitTryOn(state, value) {state.initTryOn = value},
    setUserLoadedImageObject(state, value) {state.userLoadedImageObject = value},
    setShowSubMenu(state, value) {state.showSubMenu = value},
    setShowSignUp(state, value) {state.showSignUp = value},
    setShowMobileFilters(state, value) {state.showMobileFilters = value},
    setOrderTemplateLoaded(state, value) {state.orderTemplateLoaded = value},
    setOrderResponseView(state, value) {state.orderResponseView = value},
    setModalActionMode(state, value) {state.modalActionMode = value},
    setTryonPosition(state, value) {state.tryonPosition = value},
    setProductsTryonImages(state, value) {state.productsTryonImages = value},
    setTryonActive(state, value) {state.TryonActive = value}
  },
  actions: {
    getShopDetails(context) {
      let s = new ServerInterface(context.state.url, context.state.shopCode);
      s.getShopDetails({shopCode: context.state.shopCode}).then((result) => {
        context.commit('setShopDetails', result);
        context.commit('setShopCode', result.shop_code);

        if(!context.state.shopCurrency) context.commit('setShopCurrency', result.default_currency);
        if(result.usd_rate) context.commit('setShopUSDRate', parseFloat(result.usd_rate));

        if(result.business_category == 'beauty_and_makeup') {
          context.commit('setUserDataLabel', 'User Data');
        } else if(result.business_category == 'clothing') {
          context.commit('setUserDataLabel', 'User Data');
        } else if(result.business_category == 'electronics_equipment') {
          context.commit('setUserDataLabel', 'User Data');
        } else if(result.business_category == 'groceries') {
          context.commit('setUserDataLabel', 'User Data');
        } else if(result.business_category == 'optics') {
          context.commit('setUserDataLabel', 'Prescriptions');
        } else if(result.business_category == 'women_accessories') {
          context.commit('setUserDataLabel', 'User Data');
        } else {
          context.commit('setUserDataLabel', 'User Data');
        }
      });
    },
    logout(context, token) {
        let s = new ServerInterface(context.state.url, context.state.shopCode);
        s.logout({token: token}).then((result) => {
            console.log('logout', result);
        });
        context.commit('setCustomerId', null);
        context.commit('setToken', '');

        context.commit('setLoggedInUser', null);
        context.commit('setCustomerDetails', null);
        context.commit('setAddressesList', []);
        context.commit('setPresList', []);

        // empty cart
        context.commit('setCartItems', []);
			  context.commit('setVadItem', {productId: '', productShop: ''});

        // if(token == context.state.sales_manager_token || !context.state.sales_manager_token) {
          // empty pos customers
          context.commit('setPosCustomers', []);

          context.commit('setPOSToken', '');
          context.commit('setPOSName', '');
        // }

        sessionStorage.removeItem("token");

        router.push({ path: '/dashboard' });
    },
    loggedInUserInfo(context) {
      let token = '';
      if(context.state.customer_token) token = context.state.customer_token;
      else if(context.state.sales_manager_token) token = context.state.sales_manager_token;
      if(token) {
        let s = new ServerInterface(context.state.url, context.state.shopCode);
        s.getUserInfo({token: context.state.customer_token}).then((result) => {
          // set admin panel session and redirect for non admin & customer manager
          if(context.state.PosRoles.findIndex(x => result.userRole.indexOf(x) > -1) == -1 && result.userRole.indexOf('customer') == -1) {
            // set session for admin and redirect for non admin & managers
            sessionStorage.setItem("token", context.state.customer_token);
            localStorage.setItem("token", context.state.customer_token);
            window.location = '/admin/dashboard.html';
          }

          context.commit('setCustomerId', result.id);
          context.commit('setLoggedInUser', result);
          
          // if(result.userRole.indexOf('customer_manager') > -1) {
          if(context.state.PosRoles.findIndex(x => result.userRole.indexOf(x) > -1) > -1) {
            sessionStorage.setItem("token", context.state.customer_token); // set session for admin panel
            localStorage.setItem("token", context.state.customer_token); // set session for admin panel

            context.commit('setPOSToken', context.state.customer_token);
            context.commit('setPOSName', context.state.fullName);
            context.state.customer_token = '';

            router.push({ path: '/customers' });
          }
        })
        .catch((err) => {
          console.log(err);

          context.commit('setCustomerId', null);
          context.commit('setToken', '');

          context.commit('setLoggedInUser', null);
          context.commit('setCustomerDetails', null);
          context.commit('setAddressesList', []);
          context.commit('setPresList', []);

          // empty cart
          context.commit('setCartItems', []);
          context.commit('setVadItem', {productId: '', productShop: ''});

          // if(!context.state.sales_manager_token) {
            // empty pos customers
            context.commit('setPosCustomers', []);

            context.commit('setPOSToken', '');
            context.commit('setPOSName', '');
          // }
        });
      }
    },
    getCustomerDetails(context) {
      if(context.state.customer_token) {
        let s = new ServerInterface(context.state.url, context.state.shopCode);
        s.getCustomerDetails({token: context.state.customer_token}).then((result) => {
          // console.log('getCustomerDetails', result);
          context.commit('setCustomerDetails', result);
        });
      }
    },
    async getBanners(context) {
      if(context.state.banners.list && context.state.banners.list.length) return context.state.banners;
      else {
        let s = new ServerInterface(context.state.url, context.state.shopCode);
        s.getBanners().then((result) => {
          context.commit('setBanners', result['list']);
        });
      }
    },
    getCheckoutProc(context) {
      // if(context.state.checkoutProcedures.length == 0) {
        let s = new ServerInterface(context.state.url, context.state.shopCode);
        s.listCheckoutProcs()
        .then((result) => {
          // if(Array.isArray(result)) {
          //   let list = [];
          //   for(let i in result) {
          //     if(!list.length || list.findIndex(x => x.product_cat_code == result[i].product_cat_code) == -1) {
          //       list.push(result[i]);
          //     }
          //   }
            context.commit('setCheckoutProcedures', result);
          // }
        });
      // }
    },
    listCountries(context) {
      if(context.state.countriesList.length != 0 || context.state.countriesList.findIndex(x => x.code == '') == -1) {
        let s = new ServerInterface(context.state.url, context.state.shopCode);
        s.listCountries().then((result) => {
          result.push({code: '', name: 'Country'});
          context.commit('setCountriesList', result);
        });
      }
    },
    getAddresses(context, updateList=false) {
      if(context.state.addressesList && !context.state.addressesList.length || updateList) {
        let s = new ServerInterface(context.state.url, context.state.shopCode);
        s.listAddresses({token: context.state.customer_token, userId: context.state.customer_id}).then((result) => {
          context.commit('setAddressesList', result);
        });
      }
    },
    getPresDataList(context) {
        let s = new ServerInterface(context.state.url, context.state.shopCode);
        s.listUserData({token: context.state.customer_token, customer_id: context.state.customer_id, template_code: 'pres_data'}).then((result) => {
          context.commit('setPresList', result);
        });
    },
    setPos(context, c) {
      // already selected
      if(c.token && c.token == context.state.customer_token) return false;

      if(c.token) {
        context.dispatch('posDetails', c);
      } else {
        let s = new ServerInterface(context.state.url, context.state.shopCode);
        s.customerLogin({
            token: context.state.sales_manager_token,
            userName: c.username
        }).then((result) => {
            if(result) {
              c.token = result;
              context.dispatch('posDetails', c);
            }
        });
      }
    },
    posDetails(context, c) {
      let l = context.state.posCustomers;
      if(l.findIndex(x => x.id == c.id) == -1) {
        if(l.length == 4) l.splice(l.length-1, 1);

        l.push(c);
  
        context.commit('setPosCustomers', l);
      }

      context.commit('setCustomerId', c.id);
      context.commit('setToken', c.token);

      context.dispatch('loggedInUserInfo');
      context.commit('setCustomerDetails', c);

      context.commit('setAddressesList', []);
      context.dispatch('getAddresses');
      
      context.commit('setPresList', []);
      context.dispatch('getPresDataList');

      // reset cart
      context.commit('setCartItems', c.cartItems);
    }
  },
  modules: {
  }
})
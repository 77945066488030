<template>
<div>
	<div class="dropdown">
		<label class="dropdown_button" @click="top_clicked">{{label}}</label>
		<div class="dropdown-content menu-dropdown">
			<div v-for="entry in navTree" :key="entry" >
				<a class="menu-dropdown-item" @click="sub_clicked(entry.link ?? entry.filter)" v-if="isVisible(entry)">{{entry.label}}</a>
			</div>
		</div>
	</div>
</div>
</template>

<script>

export default {
	props: {
		label:String,
		route:String,
		filter:String,
		navTree:Array
	},
    methods:{
        top_clicked() {
            if(!this.navTree || !this.navTree.length) {
                let p = this.route;
                if(this.route) p = this.route;
                else if(this.filter) p = this.filter;
                if(p == '/') location.href = location.origin;
                this.$router.push({ path: p.replaceAll('//', '/')});
            }
        },
        sub_clicked(filter) {
            this.$router.push({ path: filter.replaceAll('//', '/')});
        },
        isVisible(n) {
            if(n.visible === false) return false;

            return true;
        }
    }
}
</script>
<template>
  <div class="row-center">
    <span class="loader"></span>
  </div>
</template>

<script>
export default {
  props: {
    height: {type: Number, default: 48},
    width: {type: Number, default: 48}
  },
  data() {
    return {
      hl_color: this.$store.state.highlightColor
    }
  },
  computed: {
    loaderH() {
      return this.height + 'px';
    },
    loaderW() {
      return this.width + 'px';
    }
  }
}
</script>

<style>
.loader {
  width: v-bind(loaderH);
  height: v-bind(loaderW);
  border-width: 5px;
  border-style: solid;
  border-color: v-bind(hl_color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}
.loader:after {
  content: '';
  position: absolute;
  width: v-bind(loaderH);
  height: v-bind(loaderW);
  border-width: 5px;
  border-style: solid;
  border-color: v-bind(hl_color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% { transform: translate(-50%, -50%) scale(0) }
  60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
@keyframes pulse {
  0% , 60% , 100%{ transform:  scale(1) }
  80% { transform:  scale(1.2)}
}
</style>
<template>
    <div style="padding: 10px;">
        <h1 class="v-head">Select Image</h1>
        <!-- <hr> -->
        <div class="v-images scrollbar-style">
            <div v-for="f in faces" :key="f.id" style="width: 150px;">
                <div class="face-card" :class="{is_current: f.isCurrent, 'face-active': faceSelected?.id == f.id}">
                    <img  class="img" ref="face_image" :src="f.url" @click="select_face(f.id)"/>
                    <img src="/assets/close.svg" class="face-delete"  @click.prevent.stop="delete_face(f.id)" v-if="f.closeIcon"/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        faceSelected: {type: Object, default() {return {};}},
        faceMarks: {type: Object}
    },
    // data() {
    //     return {
    //         faces: [
    //             // {'id':'0', url: this.$store.getters.imgPathFromShopCat+'default_images/webcam.png','webCam':true, closeIcon: false},
    //             {
    //                 id: "1",
    //                 url: this.$store.getters.imgPathFromShopCat+'default_images/men1.jpg',
    //                 closeIcon: false,
    //                 default_face_marks: null
    //                 // default_face_marks: {
    //                 //     "LeftIris": {
    //                 //         "center": {
    //                 //             "x": 248.79890084266663,
    //                 //             "y": 188.21423947811127
    //                 //         },
    //                 //         "radius": 9.497451196786274
    //                 //     },
    //                 //     "RightIris": {
    //                 //         "center": {
    //                 //             "x": 147.28872776031494,
    //                 //             "y": 188.41755092144012
    //                 //         },
    //                 //         "radius": 9.497451196786274
    //                 //     },
    //                 //     "W": 400,
    //                 //     "H": 400,
    //                 //     "PD": 64,
    //                 //     "LeftPD": 30.567164179104477,
    //                 //     "RightPD": 33.43283582089552,
    //                 //     "Pix2mm": 1.5829085327977124,
    //                 //     "FaceWidth": 136.68532992747492,
    //                 //     "Center": [
    //                 //         {
    //                 //             "x": 200.58279037475586,
    //                 //             "y": 205.3894281387329
    //                 //         },
    //                 //         {
    //                 //             "x": 200.36628246307373,
    //                 //             "y": 169.18985843658447
    //                 //         },
    //                 //         {
    //                 //             "x": 200.12991428375244,
    //                 //             "y": 109.56007242202759
    //                 //         }
    //                 //     ],
    //                 //     "Width": [
    //                 //         {
    //                 //             "x": 87.07770109176636,
    //                 //             "y": 162.34824657440186
    //                 //         },
    //                 //         {
    //                 //             "x": 303.4314155578613,
    //                 //             "y": 164.04592990875244
    //                 //         }
    //                 //     ]
    //                 // }
    //             },
    //             {
    //                 id: "2",
    //                 url: this.$store.getters.imgPathFromShopCat+'default_images/women1.jpg',
    //                 closeIcon: false,
    //                 default_face_marks: null
    //                 // default_face_marks: {
    //                 //     "LeftIris": {
    //                 //         "center": {
    //                 //             "x": 480.68214413523674,
    //                 //             "y": 348.3693416416645
    //                 //         },
    //                 //         "radius": 15.568510931686792
    //                 //     },
    //                 //     "RightIris": {
    //                 //         "center": {
    //                 //             "x": 306.97777865827084,
    //                 //             "y": 350.4904330223799
    //                 //         },
    //                 //         "radius": 15.568510931686792
    //                 //     },
    //                 //     "W": 806,
    //                 //     "H": 806,
    //                 //     "PD": 67,
    //                 //     "LeftPD": 31,
    //                 //     "RightPD": 36,
    //                 //     "Pix2mm": 2.5947518219477987,
    //                 //     "FaceWidth": 148.83747846102042,
    //                 //     "Center": [
    //                 //         {
    //                 //             "x": 400.32561045885086,
    //                 //             "y": 361.88653033971786
    //                 //         },
    //                 //         {
    //                 //             "x": 400.55580055713654,
    //                 //             "y": 295.7014364004135
    //                 //         },
    //                 //         {
    //                 //             "x": 400.38667100667953,
    //                 //             "y": 201.98831623792648
    //                 //         }
    //                 //     ],
    //                 //     "Width": [
    //                 //         {
    //                 //             "x": 199.98660159111023,
    //                 //             "y": 323.9861618280411
    //                 //         },
    //                 //         {
    //                 //             "x": 586.182556271553,
    //                 //             "y": 323.4561216831207
    //                 //         }
    //                 //     ]
    //                 // }
    //             }
    //         ],
    //     }
    // },
    computed: {
        faces() {
            let uimg = [];
            if(this.$store.state.userLoadedImageObject) uimg.push(this.$store.state.userLoadedImageObject);

            let face1DFM = null;
            let face2DFM = null;
            if(this.faceMarks) {
                if(this.faceSelected?.id === "0" && uimg.length) uimg[0].default_face_marks = this.faceMarks;
                if(this.faceSelected?.id === "1") face1DFM = this.faceMarks;
                if(this.faceSelected?.id === "2") face2DFM = this.faceMarks;
            }

            return uimg.concat([
                {
                    id: "1",
                    url: this.$store.getters.imgPathFromShopCat+'default_images/men1.jpg',
                    closeIcon: false,
                    default_face_marks: face1DFM
                },
                {
                    id: "2",
                    url: this.$store.getters.imgPathFromShopCat+'default_images/women1.jpg',
                    closeIcon: false,
                    default_face_marks: face2DFM
                }
            ]);
        }
    },
    methods: {
        select_face(id) {
            let i = 0;
            while (i < this.faces.length) {
                if (this.faces[i].id === id) {
                    this.$emit('select-face', this.faces[i]);
                    break;
                }
                i++;
            }
        },
        delete_face(id) {
            if(id == undefined) return;
            // this.$emit('remove-face', this.faces[1]);
            this.$store.commit('setUserLoadedImageObject', null);
            this.select_face("1");
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-head {
    text-align: center;
    text-transform: uppercase;
    padding: 10px 6px;
}

.v-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 6px;
    max-height: 330px;
    overflow: auto;
}
@media(max-width: 768px){
    .v-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 6px;
    max-height: 400px;
    overflow: auto;
}
}
.face-card {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-bottom: 10px;
    /* width: 120px;
    height: 150px; */
    /* margin: 4px; */
    /* border: solid rgb(230, 230, 230) 1px; */
}

.face-card:hover {
    border: rgb(230, 2, 2) solid 1px;
}

.face-active {
    border: rgb(2, 230, 40) solid 1px;
}

.face-active:hover {
    border: rgb(2, 230, 40) solid 1px;
}

.img {
    max-width: 100%;
    max-height: 100%;
}
.is_current{
    width: 32px;
    padding: 9px;
    border: rgb(7, 3, 221) solid 1px;
}

.face-delete {
    position: absolute;
    right: 1px;
    top:1px;
    width: 22px;
    height: 22px;
    z-index: 20;
}
</style>
<template>
    <!-- <div v-if="wa_link"> -->
        <a :href="'https://wa.me/'+wa_link" target="_blank" class="link-wa"><img src="/assets/icons-whatsapp.png" /></a>
    <!-- </div> -->
</template>

<script>
export default {
    computed: {
        wa_link() {
            return this.$store.state.shopInfo ? this.$store.state.shopInfo.mediaLinks.whatsapp_link : '';
        }
    }
}
</script>

<style scoped>
.link-wa {
    position: fixed;
    right: 30px;
    bottom: 110px;
    z-index: 999;
    opacity: 1;
    transition: all 0.4s ease;
    transform: translateY(60px);
    -ms-transform: translateY(60px);
}

img {
    width: 60px;
}
</style>
<template>
	<div :class="[webCamEnabled ? 'primary-colors' : 'primary-colors', 'tryon-dialog']" v-show="$store.state.showTryonModal">
		<div class="tryon-border">
			<img src="/assets/close.svg" class="vtryon-close" @click="close()" />

			<div v-if="faceSelectorEnabled" style="padding: 10px 6px;">
				<img_selector :isShowing="faceSelectorEnabled" @select-face="select_face" @remove-face="remove_face" :faceSelected="selectedImage" :faceMarks="faceMarksObject" />

				<div class="row-center" v-show="isLoading">
					<div class="loader"></div>
					<h2 ref="loader_msg">Loading...</h2>
				</div>
			</div>

			<WebCam v-if="webCamEnabled" ref="webcam" @close-webcam="closeWebcam" @try-again="tryAgain" @process-captured="processCaptured" />

			<div v-show="markedFace">
				<div style="max-height: calc(100vh - 60px);">
					<img class="analys" src="" ref="analysis_img" alt="" style="height: calc(100vh - 60px);">
				</div>
				<canvas ref="analysis_canvas" style="display: none;"></canvas>
			</div>
			<input type="file" style="display: none;" ref="fileupload" @change="fileUploaded">
		</div>
		<!-- Fixed Footer -->
		<div class="fixed-footer">
			<div class="card-icons tryon-icons" @click="browseImage()">
				<img src="/assets/business_categories/optics/png/taro_gulzari-01.png" alt="" />
				<small>Upload Image</small>
			</div>
			<div class="card-icons tryon-icons" @click="open_web_cam()">
				<img src="/assets/business_categories/optics/png/taro_gulzari-02.png" alt="" />
				<small>Take Photo</small>
			</div>
			<div class="card-icons tryon-icons" @click="show_face()">
				<img src="/assets/business_categories/optics/png/taro_gulzari-04.png" alt="" />
				<small>Face Analysis</small>
			</div>
			<div class="card-icons tryon-icons" @click="switchTryon()">
				<img src="/assets/business_categories/optics/png/taro_gulzari-03.png" alt="" />
				<small class="pt-1">{{ tryon_status }} Tryon</small>
			</div>
		</div>
	</div>
</template>

<script>
//import tryon_face from './optics/tryon/face.vue';
import img_selector from './img_selector.vue';
import WebCam from "./webcam.vue";

export default {
	inject:['tryon'],
  	components: {
		WebCam,
		//tryon_face,
		img_selector
    },
    // props: {
	// 	isShowing: {Boolean, default: false},
    // },
    data () {
        return {
            webCamEnabled: false,
            faceSelectorEnabled: true,
            showImage: false,
			selectedImage: null,
			markedFace: '',
			isLoading: false,
			faceMarksObject: null
        };
    },
  	methods: {
		tryAgain() {
			this.closeWebcam();
		},
		close() {
			this.isLoading = false;
			if(this.markedFace) this.closeAnalysis();
			else if(this.webCamEnabled) this.closeWebcam();
			else {
				// this.$emit('closeTryon');
				this.$store.commit('setShowTryonModal', false);
			}
		},
		open_web_cam(){
			this.webCamEnabled=true;
			this.faceSelectorEnabled=false;
		},
		closeWebcam() {
			this.$refs.webcam.closeCam();
			this.webCamEnabled=false;
			this.faceSelectorEnabled=true;
		},
		closeAnalysis() {
			this.markedFace = '';
			this.faceSelectorEnabled=true;
		},
		async processCaptured(img) {
			this.closeWebcam();

			// let res = URL.createObjectURL(img);
			let res = await this.setImageReso(img);

			let fc = {id: '0', url: res, closeIcon: true, default_face_marks: ''};
			this.$store.commit('setUserLoadedImageObject', fc);

			this.select_face(fc);
		},
		browseImage(){
			this.$refs.fileupload.click();
		},
		async fileUploaded(e) {
			let res = await this.blobToBase64(e.target.files[0]);
			res = await this.setImageReso(res);
			// let res = URL.createObjectURL(e.target.files[0]);

			let fc = {id: '0', url: res, closeIcon: true, default_face_marks: ''};
			this.$store.commit('setUserLoadedImageObject', fc);

			this.select_face(fc);
			e.target.value = null;
		},
		async show_face() {
			this.isLoading = true;
			this.$refs.loader_msg.innerHTML = 'Analyzing Face...';
			let self = this;
			
			let c = this.$refs.analysis_canvas;
			this.tryon.tryonlib.drawFacemarks(c).then((r) => {
				self.markedFace = URL.createObjectURL(r);
				this.$refs.analysis_img.src = self.markedFace;
				self.webCamEnabled = false;
				self.faceSelectorEnabled = false;
				self.isLoading = false;
			}).catch((e) => {
				this.$refs.loader_msg.innerHTML = e;
			});
			// this.showImage = !this.showImage;
		},
		remove_face() {
			// this.$store.commit("setProductsTryonImages", {});
			// this.$store.commit("setTryonActive", {});
            // localStorage.removeItem('IsTryonSelected');
			this.$store.commit('setUserLoadedImageObject', null);
			window.location.reload();
		},
		async select_face(face) {
			if(face && face.url) {
				if(!this.$store.state.tryOnSwitch) this.switchTryon();
				this.closeAnalysis();
				
				this.isLoading = true;
				if(this.$refs.loader_msg) this.$refs.loader_msg.innerHTML = 'Detecting Face...';

				let url = face.url;
				let res = '';
				if(url.indexOf('data:') > -1 && url.indexOf(';base64,/') > -1) {
				// 	let res1 = await fetch(url);
				// 	res = URL.createObjectURL(await res1.blob());
					res = url;
				} else if(url.includes("localhost")) {
					res = url;
				} else {
					let resp = await fetch(url).catch((err)=>{
						console.log('tryon face fetch', err);
						this.$swal('Error', err, 'error');
					}); 
					let b = await resp.blob();
	
					res = await this.blobToBase64(b);
					// res = URL.createObjectURL(b);
				}
				// res = await this.setImageReso(res);
				this.faceMarksObject = await this.tryon.tryonlib.AddNewImage(res, face.default_face_marks);
				
				// alert(JSON.stringify(this.faceMarksObject))
				// let rr = Math.random();
				// this.tryon.tryonlib.faceMarks = rr;
				this.$store.commit('setIsTryonSelected', JSON.stringify({...face, ...{default_face_marks: this.faceMarksObject}}));
	
				this.selectedImage = face;
				this.close();
			}
		},
		blobToBase64(blob) {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			return new Promise(resolve => {
				reader.onloadend = () => {
					resolve(reader.result);
				};
			});
		},
		setImageReso(src) {
			return new Promise((resolve, reject) => {
				var image = new Image();
				image.onload = function () {
					// Resize the image
					var canvas = document.createElement('canvas'),
						max_size = 600,// TODO : pull max size from a site config
						width = image.width,
						height = image.height;
					if (width > height) {
						if (width > max_size) {
							height *= max_size / width;
							width = max_size;
						}
					} else {
						if (height > max_size) {
							width *= max_size / height;
							height = max_size;
						}
					}
					canvas.width = width;
					canvas.height = height;
					canvas.getContext('2d').drawImage(image, 0, 0, width, height);
					var dataUrl = canvas.toDataURL('image/png');
					resolve(dataUrl);
				}
				image.onerror = () => reject('Failed to change image resolution')
				image.src = src
			});
		},
		switchTryon() {
			this.$store.commit("setTryOnSwitch", !this.$store.state.tryOnSwitch);
			if(!this.$store.state.tryOnSwitch) {
				this.$store.commit('setIsTryonSelected', "");
				this.$store.commit("setProductsTryonImages", {});
				let productVtryActive = this.$store.state.TryonActive;
				if(typeof productVtryActive == "object" && Object.keys(productVtryActive).length) {
					let objkeys = Object.keys(productVtryActive);
					for(let i in objkeys) {
						productVtryActive[objkeys[i]] = false;
					}
				}
				this.$store.commit('setTryonActive', productVtryActive);
				this.close();
			} else {
				this.select_face(this.selectedImage);
			}
		}
  	},
	computed: {
		tryon_status() {
			return this.$store.state.tryOnSwitch ? "Disable" : "Enable";
		}
	},
	created() {
		try {
            if(this.$store.state.IsTryonSelected) {
                if(this.$store.state.IsTryonSelected[0] == '{' && this.$store.state.IsTryonSelected[this.$store.state.IsTryonSelected.length-1] == '}') {
                    let face = JSON.parse(this.$store.state.IsTryonSelected);

					this.$store.commit('setIsTryonSelected', "");

					if(face.id) this.select_face(face);
                }
            }
        } catch(ex) {
			console.error(ex);
        }
	}
}
</script>


<style>
.primary-colors {
    background-color: rgb(31, 85, 145) !important;
    color: rgb(255, 255, 255) !important;
    box-shadow: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
}
.tryon-dialog {
    min-height: 50px;
    min-width: 400px;
    width: auto;
    box-shadow: 10px 10px #000000;
    position: fixed; /* Stay in place */
    z-index: 1111111; /* Sit on top */
    margin: auto;
    top: 15px;
    right: 10px;
    padding: 6px;
    overflow: hidden;
}

.tryon-border {
    padding: 10px 6px;
    border: 8rem 0rem 8rem 0rem solid #FFFFFF !important;
}

.tryon-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 4px;
}

.tryon-icons img {
    width: 28px;
    margin-bottom: 8px;
}

.tryon-icons small {
    font-size: 12px;
    font-weight: 500;
}

.row-center {
    align-items: center;
}

.vtryon-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    z-index: 20;
}

.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 26px;
    height: 26px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin: 16px;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.fixed-footer{
	display: flex;
    align-items: center;
    justify-content: space-around;
}

/* Fixed Footer - Only for mobile view */
@media (max-width: 768px) {
    .tryon-dialog {
        min-width: 100vw;
        width: 100vw;
        left: 0;
        right: 0;
        padding: 0;
        border: none;
    }
    .tryon-border {
        padding-bottom: 60px; /* Space for the fixed footer */
    }
	.tryon-dialog {
        width: 100% !important;
        height: 100% !important;
        right:0px;
		top:0px !important;
    }
    .fixed-footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 60px;
        background-color: rgb(31, 85, 145);
        z-index: 1111112;
    }
}

@media (max-width: 600px) {
    .analys {
        width: 100%;
        height: auto !important;
    }
}

</style>
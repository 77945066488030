<template>
    <div class="row-center">
        <h2>{{label}}</h2>
    </div>
</template>

<script>
export default {
    props: {
        label: {String, default: "No records found"},
    }
}
</script>

<style scoped>
.row-center {
    align-items: flex-start;
}
</style>
<template>
    <div v-if="showBar" :class="{'mobile-search-bar2': this.$isMobile(), 'search-bar': !this.$isMobile()}">

        <div class="search-icon" @click="clicked">
            <fa icon="times" style="margin-right: 5px;" v-show="searchValue" @click="clearSearch" />
            <fa icon="search" />
        </div>
      <input type="text" placeholder="Search..." v-model="searchValue" class="search-bar-input2" v-on:keyup.enter="clicked">
    </div>
</template>

<script>
// import ServerInterface from '../../interface/be_interface'

export default {
    props: {
        label:String,
        navTree:Object,
        showBar: {Boolean, default: true},
    },
    data() {
        return {
            searchValue: "",
        }
    },
    watch: {
        '$route.params': function() {
			if(this.$route.params.cat != 'search') this.searchValue = '';
		},
        searchValue(val) {
            if(!val) this.clearSearch();
        }
    },
    methods:{
        clicked() {
            if(this.searchValue) this.$router.push({
                name: 'Products',
                params: {cat: 'search'},
                query: {filterBy: this.searchValue}
            });
        },
        clearSearch() {
            this.searchValue = '';
            if(this.$route.name == 'Products') this.$router.push({name: 'Products'});
        }
    },
    created() {
        if(localStorage.getItem('searchValue')) {
            this.searchValue = localStorage.getItem('searchValue');
            localStorage.removeItem('searchValue');
            this.clicked();
        }
    }
}
</script>
<template>
  <div>
    <video
      class="video__Height"
      id="video"
      style="width: 100%"
      ref="video_div"
      autoplay
      muted
      v-show="webcamState == 'video'"
    ></video>

    <div class="row-center">
      <button class="vtryon-button" ref="cap_button" @click="CaptureImage()">
        Capture Image
      </button>

      <div class="loader" ref="loader"></div>
      <h2 ref="msg_init">Loading Camera...</h2>
    </div>

    <div style="width: 100%; height: 55vh" v-show="webcamState == 'frozen'">
      <img
        class="canvas__Image"
        src=""
        ref="canvas_img_div"
        alt=""
        width="356"
        height="400"
      />
    </div>
    <canvas
      id="canvas"
      ref="canvas_div"
      width="400"
      height="400"
      style="display: none"
    >
      Canvas not supported
    </canvas>

    <div class="row-center">
      <div ref="again_button">
        <button class="vtryon-button" @click="tryAgain">Try Again</button>
        <button class="vtryon-button" @click="process">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      webcamState: "video",
      ctx: null,
      face_b64: "",
      face_blob: "",
    };
  },
  methods: {
    setStateInit() {
      this.$refs.video_div.style.display = "block";
      this.$refs.canvas_img_div.style.display = "none";
      this.$refs.again_button.style.display = "none";

      this.$refs.cap_button.style.display = "none";
      this.$refs.loader.style.display = "block";
      this.$refs.msg_init.style.display = "block";
    },
    setStateVideo() {
      this.$refs.video_div.style.display = "block";
      this.$refs.canvas_img_div.style.display = "none";
      this.$refs.again_button.style.display = "none";

      this.$refs.cap_button.style.display = "block";
      this.$refs.loader.style.display = "none";
      this.$refs.msg_init.style.display = "none";
    },
    setStateFrozen() {
      this.$refs.video_div.style.display = "none";
      this.$refs.canvas_img_div.style.display = "block";
      this.$refs.cap_button.style.display = "none";
      this.$refs.again_button.style.display = "block";
      this.$refs.loader.style.display = "none";
      this.$refs.msg_init.style.display = "none";
    },
    async startVideo() {
      this.setStateInit();

      this.webcamState = "video";

      const camera_video = this.$refs.video_div;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          // Media constraints
          const constraints = {
            audio: false,
            video: {
              width: 1280,
              // height: 400
            },
            // video: true
          };

          let stream = await navigator.mediaDevices.getUserMedia(constraints);
          // camera_video.translate(constraints.video.width, 0);
          // camera_video.scale(-1, 1);
          camera_video.srcObject = stream;
        } catch (ex) {
          console.log(ex);
          this.$swal("Error", "Unable to use camera!", "error");
          this.$emit("close-webcam", "");
          this.closeCam();
          return;
        }
        this.setStateVideo();
      } else {
        this.$swal("Error", "Camera Not Available!", "error");
        this.$emit("close-webcam", "");
        this.closeCam();
        return;
      }
    },
    closeCam() {
      if(this.$refs.video_div && this.$refs.video_div.srcObject && this.$refs.video_div.srcObject.getTracks().length) {
        console.log(this.$refs.video_div.srcObject.getTracks());
        this.$refs.video_div.srcObject.getTracks().forEach(function(track) {
          track.stop();
        });

        this.$refs.video_div.srcObject = null;
      }
    },
    async CaptureImage() {
      const camera_video = this.$refs.video_div;
      const canvas = this.$refs.canvas_div;
      const canvasImg = this.$refs.canvas_img_div;

      // console.log(camera_video.videoWidth);
      // console.log(camera_video.videoHeight);

      // let W=camera_video.clientWidth;
      // let H=camera_video.clientHeight;

      // canvas.width=camera_video.clientWidth;
      // canvas.height=camera_video.clientHeight;

      let W = camera_video.videoWidth;
      let H = camera_video.videoHeight;

      canvas.width = camera_video.videoWidth;
      canvas.height = camera_video.videoHeight;

      this.webcamState = "frozen";

      let ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, W, H);
      ctx.translate(W, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(camera_video, 0, 0, W, H);

      this.face_b64 = canvas.toDataURL("image/png");
      canvasImg.src = this.face_b64;

      canvas.toBlob((blob) => {
        this.face_blob = blob;
      });

      this.setStateFrozen();
    },
    tryAgain() {
      this.setStateVideo();
    },
    process() {
      this.closeCam();
      this.$emit("process-captured", this.face_b64);
      // this.$emit('process-captured', this.face_blob);
    },
    // close() {
    //     this.$emit("close-webcam","");
    // },
  },
  mounted() {
    this.startVideo();
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content: center;
  background-color: white;
}

@media (min-width: 768px) {
  .video__Height {
    height: 75vh !important;
  }
}
@media (max-width: 768px) {
  .video__Height {
    height: 60vh !important;
  }
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 26px;
  height: 26px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 16px;
}
.canvas__Image {
  width: 100%;
  height: 50vh;
  object-fit: contain !important;
}

h2 {
  font: 15px Arial, sans-serif;
  font-size: 1.4rem;
  text-align: center;
  color: darkcyan;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .outer-div {
    max-width: 95%;
    max-height: 95%;
  }
}
.vtryon-button {
  width: 120px;
  height: 40px;
  border-radius: 6px;
  border: none;
  margin: 6px;
  cursor: pointer;
}

video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>

<template>
  <div>
    <!-- Mobile::Navbar -->
    <div class="tsp-mob-nav" :style="tspMobNavStyle" v-if="this.$isMobile()">
      <div class="tsp-w33">
        <!-- <icon_bar /> -->
        <div class="header-icon" v-if="this.$isMobile()">
          <fa icon="bars" @click.prevent.stop="toggleSubmenu()" />
        </div>
      </div>
      <div class="tsp-w33 tsp-textcenter">
        <img
          :src="logo"
          class="logo-mobile"
          @click="home"
          style="aspect-ratio: 1/0; padding: 10px; margin: 0px;"
        />
      </div>
      <!-- Search Button -->
      <div v-if="$store.getters.isTryOnCategory" class="tsp-textcenter">
        <fa
          icon="search"
          type="button"
          class="search__Icon"
          @click="toggleModal"
        />
      </div>

      <div
        class="my__Modal"
        v-if="showModal"
        @close="showModal = false"
        @keyup.enter="closeModalOnEnter"
      >
        <search />
      </div>

      <div class="tsp-w33 tsp-flex-row">
        <div v-if="!$store.getters.isTryOnCategory" class="tsp-textcenter">
          <fa
            icon="search"
            type="button"
            class="search__Icon"
            @click="toggleModal"
          />
        </div>
        <icon_bar />

        <!-- <div class="nav-logo-container"> -->
        <div v-if="$store.getters.isTryOnCategory" class="tsp-tryon vtryon-bg">
          <div class="try-on-mob-container">
            <img :src="imgPath()" @click="tryonClicked()" title="Tryon" />
            <!-- <span class="try-on-mob">TRY ON</span> -->
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>

    <!-- Desktop and for other devices::Navbar -->
    <div
      class="top-bar-stack"
      v-else
      @click="$store.commit('setShowSubMenu', false)"
    >
      <pos_header />
      <div class="tsp-navbar all-page-primary-header-footer">
        <div class="header-icon" v-if="smallScreenIcons">
          <fa icon="bars" @click.prevent.stop="toggleSubmenu()" />
        </div>

        <div class="nav-logo-container">
          <img :src="logo" class="logo" @click="home" />
        </div>

        <div class="menu" ref="headerMenu" v-if="!smallScreenIcons">
          <div v-for="dd in $store.state.headerFilterTree" :key="dd">
            <drop_down
              :label="dd.label"
              :route="dd.link"
              :filter="dd.filter"
              :navTree="dd.children"
              v-if="isVisible(dd)"
            />
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <icon_bar :smallScreenIcons="smallScreenIcons" />

          <div class="nav-logo-container">
            <div
              v-if="$store.getters.isTryOnCategory"
              class="vtryon-bg"
              style="display: flex; flex-direction: column; align-items: center"
            >
              <img
                :src="imgPath()"
                class="Image__Class"
                @click="tryonClicked()"
                title="Tryon"
              />
            </div>
          </div>
          <div
            v-if="$store.state.customer_token && $store.state.customerDetails"
            class="dropdown"
            style="margin-right: 1rem !important"
          >
            <button
              class="btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div
                style="
                  background-color: rgb(255, 255, 255);
                  color: black;
                  padding: 4px;
                  padding-inline: 14px;
                  border-radius: 50%;
                  margin-right: 8px;
                  font-size: 24px;
                  font-weight: 500;
                "
              >
                {{
                  $store.state.customerDetails.first_name
                    .charAt(0)
                    .toUpperCase()
                }}
              </div>
            </button>
            <ul
              class="dropdown-menu"
              style="padding: 0.3rem !important"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="user()"
                >
                  <div
                    class="header-icon m-0"
                    title="Dashboard"
                    v-if="!this.$isMobile()"
                  >
                    <fa icon="user" style="font-size: 18px" />
                  </div>
                  {{ $store.state.customerDetails.first_name }}
                  {{ $store.state.customerDetails.last_name }}
                </div>
              </li>
              <li>
                <div style="display: flex; align-items: center">
                  <div
                    class="header-icon dsmnone m-0"
                    v-if="
                      $store.state.customer_token ||
                      ($store.state.sales_manager_token && !this.$isMobile())
                    "
                    title="Logout"
                    @click.prevent.stop="logout()"
                  >
                    <fa icon="sign-out-alt" style="font-size: 18px" /> Logout
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <div style="margin-right: 2rem !important">
              <div
                class="dropdown dsmnone"
                style="padding: 10px"
                v-if="!this.$isMobile()"
              >
                <label
                  class="dropdown_button"
                  style="font-size: 14px; border-radius: 12px"
                >
                  <fa icon="user" style="font-size: 22px; cursor: pointer" />
                </label>
                <div
                  style="
                    border-radius: 8px;
                    margin-left: -6rem !important;
                    width: 11rem !important;
                  "
                  class="dropdown-content p-2 menu-dropdown"
                >
                  <div style="cursor: pointer;" @click="user()">
                    <fa
                      icon="user"
                      style="font-size: 22px;"
                    
                    />
                    Login
                  </div>
                  <div  style="cursor: pointer;" @click="orderTracking()" class="pt-2">
                    <fa icon="truck" style="font-size: 22px; cursor: pointer" />
                    Track Your Order
                  </div>
                </div>
              </div>
              <!-- <fa
                icon="user"
                style="font-size: 22px; cursor: pointer"
                @click="user()"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <tryon
      v-if="$store.state.initTryOn && $store.getters.isTryOnCategory"
      :isShowing="tryon_flag"
      @closeTryon="closeTryon"
    /> -->
    <tryon v-if="$store.state.initTryOn && $store.getters.isTryOnCategory" />
  </div>
</template>

<script>
import pos_header from "@/components/point_of_sales/pos_header.vue";
import drop_down from "@/components/navigate/drop_down.vue";
import search from "@/components/navigate/MobileSearch.vue";
import icon_bar from "@/components/navigate/header_icon_bar.vue";
import tryon from "@/components/tryon/tryon_dialog.vue";
// import switch_btn   from '@/controls/switch.vue';

export default {
  inject: ["tryon"],
  components: {
    drop_down,
    search,
    icon_bar,
    pos_header,
    tryon,
    // switch_btn
  },
  data() {
    return {
      // tryon_flag: false,
      showModal: false,
      smallScreenIcons: false,
    };
  },
  // watch: {
  //   "$store.state.showTryonModal": function (val) {
  //     if (val) this.tryon_flag = val;
  //   },
  // },
  methods: {
    toggleSubmenu() {
      this.$store.commit("setShowSubMenu", !this.$store.state.showSubMenu);
    },
    tryonClicked() {
      // this.tryon_flag = true;
      this.$store.commit(
        "setShowTryonModal",
        !this.$store.state.showTryonModal
      );
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },

    closeModalOnEnter(event) {
      if (event.key === "Enter") {
        // Close your Vue.js modal when the Enter key is pressed
        this.showModal = false;
      }
    },
    orderTracking(){
      this.$router.push({
        path: "/orderTracking",
      });
    },
    user() {
      this.$router.push({
        path: "/dashboard",
      });
    },
    logout() {
      this.$store.dispatch("logout", this.$store.state.customer_token);
    },
    // closeTryon() {
    //   this.tryon_flag = false;
    // },
    // clicked() {
    //   this.$store.commit("setMessage", "CHANGED");
    // },
    home() {
      location.href = location.origin;
      // this.$router.push({ path: '/'})
    },
    imgPath() {
      return this.$store.getters.imgPathFromShopCat + "Groupicon.png";
    },
    isVisible(n) {
      if (n.visible === false) return false;
      return true;
    },
    cart() {
      this.$router.push({
        path: "/cart",
      });
    },
  },
  computed: {
    logo() {
      return this.$store.getters.getShopLogo;
    },
    marginTop() {
      if (this.$store.state.sales_manager_token) return "58px";
      return "0px";
    },
    tspMobNavStyle() {
      return this.showModal ? { marginBottom: "5rem" } : {};
    },
  },
  mounted() {
  
    if (window.innerWidth >= 1821) {
      if (this.$refs.headerMenu?.clientWidth > window.innerWidth / 1.5) {
        // this.$refs.headerMenu.style.display = "none";
        this.smallScreenIcons = true;
      }
    } else if (this.$refs.headerMenu?.clientWidth > window.innerWidth / 2) {
      // this.$refs.headerMenu.style.display = "none";
      this.smallScreenIcons = true;
    }

    // if (localStorage.getItem("triggerTryon")) {
    //   this.tryon_flag = true;
    //   localStorage.removeItem("triggerTryon");
    //   this.clicked();
    // }
  },
};
</script>

<style scoped>
.tsp-navbar {
  margin-top: v-bind(marginTop);
}
.tsp-mob-nav {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 75px;
  background: black;
  color: #ffffff;
}
.tsp-w33 {
  width: 33.33%;
}
.tsp-textcenter {
  text-align: center;
}
.btn:hover,
.btn.show {
  border: none !important;
}
.search__Icon {
  margin-top: 0.5rem;
  font-size: 20px !important;
}
.my__Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  position: absolute !important;
  top: 6rem;
  z-index: 1000;
}
.tsp-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
}
.tsp-tryon {
  width: 45px;
  height: max-content;
  margin-left: 5px;
  margin-right: 5px;
}
.try-on {
  color: black;
  font-weight: 600;
  letter-spacing: 0.1rem;
  margin-top: -12px;
}
.try-on-mob {
  color: black;
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-size: 8px;
}
.Image__Class {
  padding-left: 0px !important;
}
.try-on-mob-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.try-on-mob-container img {
  max-width: 100%;
}
@media (max-width: 768px) {
  .try-on-mob-container img {
    max-width: 120%;
  }
}
</style>

import TryonLib from '../business_categories/optics/tryon/tryonlib.js';
class Tryon {
    constructor() {
        this.initOK=false;
    }

    init(business_categories, tcanvas) {
        if(business_categories=="optics") {
            this.tryonlib= new TryonLib();
            this.setCanvas(tcanvas);
            return this.tryonlib.init().then((r) => {
                this.initOK = r;
                return r;
            });
        }
                
        return false;
    }

    setCanvas(tcanvas) {
        this.tryonlib.canvas_div = tcanvas;
    }
}


export default Tryon;


<template>
<sliderMenu :show="show" :menuList="subMenuItems"  @subMenuClick="subMenuClick"/>


</template>

<script>
import ServerInterface from '@/interface/be_interface.js'
import sliderMenu from './slider_menu.vue';

export default {
    components: {
        sliderMenu
    },
    props:{
        show:{type:Boolean, default:false},
    },
    data() {
      return {
          subMenuItems:[]
      }
    },
    created() {
        this.subMenuItems=[];
        if(!this.isUserLoggedIn()) this.subMenuItems.push('Login, User Profile')
        
        this.$store.state.productCategories.list.forEach(cat => {
            this.subMenuItems.push(cat.label);
        });
        
        if(this.$isMobile()) {
            this.subMenuItems.push('Filter');
            this.subMenuItems.push('Favourites');
        }
        if(this.isUserLoggedIn()) this.subMenuItems.push('Logout');
    },
    watch: {
        '$store.state.customer_token': function(val) {
            if(val) {
                this.subMenuItems.splice(this.subMenuItems.indexOf('Login, User Profile'), 1);
                this.subMenuItems.push('Logout');
            } else {
                this.subMenuItems.splice(this.subMenuItems.indexOf('Logout'), 1);
                let arr = ['Login, User Profile'];
                this.subMenuItems = arr.concat(this.subMenuItems);
            }
        }
    },

    methods:{
        subMenuClick(item){
            item=item.toLowerCase();
            if(item.includes('login')) {this.$router.push({path: '/dashboard'});}
            else if(item.includes('logout')) {this.logout();}
            else if(item.includes('filter')) {this.filter();}
            else if(item.includes('favourites')) { this.$router.push({path: '/favourites'}); }
            else this.filterCat(item);
        },
        filterCat(catName) {
            let categ = this.$store.state.productCategories.list.filter(x => x.label.toLowerCase() == catName);
            if(categ.length) {
                this.$router.push({path: '/products/'+categ[0].code});
            }
        },
        isUserLoggedIn() {
            const loggedin = this.$store.state.customer_token != "";
            return loggedin;
        },
        filter() {
            this.$store.commit('setShowMobileFilters', true);
        },
        logout() {
            let s = new ServerInterface(this.$store.state.url, this.$store.state.shopCode);
            s.logout({token: this.$store.state.customer_token}).then((result) => {
                console.log('logout', result);
            });
            this.$store.commit('setCustomerId', null);
            this.$store.commit('setToken', '');
            this.$store.commit('setPOSToken', '');
            this.$store.commit('setPOSName', '');

            this.$store.commit('setLoggedInUser', null);
            this.$store.commit('setCustomerDetails', null);
            this.$store.commit('setAddressesList', []);
            this.$store.commit('setPresList', []);

            // empty cart
            this.$store.commit('setCartItems', []);
			this.$store.commit('setVadItem', {productId: '', productShop: ''});

            // empty pos customers
            this.$store.commit('setPosCustomers', []);
        }
    },
}
</script>

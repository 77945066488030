import axios from "axios";
// import store from '../store/index.js'

class ServerInterface {
    constructor(url, shopCode) {
        this.shopCode = shopCode;
        this._request_files = [];

        // let protocol = 'https:';

        if(!url.includes('/api2')) {
            if(location.hostname.includes('tarospace.local')) {
                url = 'http://api.tarospace.local';
            }
            // this.cloudFrontUrl = store.state.s3Url;
    
            // this.domain = shopCode+'.'+url;
            // this.url = protocol+'//api.'+url+'/interface/index.php';
            this.url = url+'/interface/index.php';
            // this.url = 'http://api.tarospace.local/interface/index.php';
        } else {
            this.url = url;
        }
    }

    async getLandingPage() {
        let postBody = this.json("shops.landingPage", {});

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }         
            })
        });
    }

    async getShopDetails() {
        let postBody = this.json("home.getShopDetails", {});

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }         
            })
        });
    }
    
    async getShopFilterDefs() {
        let postBody = this.json("shops.getShopFilterDefs", {});

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }         
            })
        });
    }

    async getBanners() {
        let postBody = this.json("shops.getShopBanners", {});

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }
                else
                {
                    reject("error")
                }
            })
        });
    }

    async getSlides() {
        let postBody = this.json("shops.getShopSlides", {});

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }         
            })
        });
    }

    // getUrl(method,params)
    // {
    //     let url = this.url + "?json="+this.json(method,params);
    //     return url;
    // }

    // get the request files array
    getRequestFiles()
    {
        return this._request_files;
    }

    // add to our array of File() objects that will be sent with a request
    addRequestFile(variable_name, file_object)
    {
        // validate the variable name string against a regex check
        var regex = new RegExp(/^([A-Za-z0-9_]+)(\[\])?$/);
        if (!regex.test(variable_name))
        {
            throw new Error("1st argument to addRequestFile() is not a valid variable name !");
        }

        // file_object must be a file
        if (typeof file_object != 'object')
        {
            throw new Error("2st argument to addRequestFile() must be a File() object!");
        }

        this._request_files.push({"variable_name" : variable_name, "file" : file_object});
        return this;
    }

    // clear the request files array, so that no files will be sent with further jsonrpc requests
    clearRequestFiles()
    {
        this._request_files.length = 0;
        return this;
    }

    json(method, params)
    {
        // console.log("Logging the states in the Function", this.shopCode, this.domain, params)
        // params.shopCode=params.sub_shopCode ?? this.shopCode;
        if(this.url.includes('/api2')) params.shopCode=params.sub_shopCode ?? this.shopCode;
        // params.sub_shopCode=this.shopCode;
        // params.domain = this.domain;


        // let msg = {
        //     jsonrpc:'2.0',
        //     method:method,
        //     params : params,
        //     id:this.makeid()
        // }

        // build the jsonrpc-syntax body
        let rpcBody = {
            jsonrpc:'2.0',
            method:method,
            params : params,
            id:this.makeid()
        };

        // if there are files supposed to go with this request, create a FormData object
        if (this.getRequestFiles().length > 0)
        {
            let wrapped_data = new FormData();
            wrapped_data.append('json', JSON.stringify(rpcBody));
            for (let i = 0; i < this.getRequestFiles().length; i++)
            {
                wrapped_data.append(this.getRequestFiles()[i].variable_name, this.getRequestFiles()[i].file);
            }
            rpcBody = wrapped_data;
        } else rpcBody = JSON.stringify(rpcBody);

        return rpcBody;
    }

    getImageUrl(method,imageName,otherParams={})
    {
        let params = {'image':imageName};
        params = {...params, ...otherParams};
        let url = this.url + "?json="+this.json(method,params);
        if(method == 'products.downloadProductPicture') {
            // url = `${this.cloudFrontUrl}${otherParams.shopCode ?? this.shopCode}/_product_files/${otherParams.productId}/${imageName}`;
            url = this.url+`/${otherParams.shopCode}/product_files/${otherParams.productId}/${imageName}`;
        }
        return url;
    }

    makeid() {
        let length=8;
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }
    async chatListing(params) {
        let postBody = this.json("chat.listMessages", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }         
            })
        });
        // console.log("Chat Listing is being called");
        // const payload = {
        //     jsonrpc: "2.0",
        //     method: "chat.listMessages",
        //     params: {
        //         shopCode: "gulzarioptics",
        //         orderRef: "0.SAJVMN"
        //     },
        //     id: "jsonrpcfg3asnuhgh9"
        // };
    
        // try {
        //     console.log("Sending the request on the following...", this.url)
        //     const response = await axios.post(this.url, payload);
        //     console.log("Response is ", response.data);
        //     return response.data; 
        // } catch (error) {
        //     console.error("Error making API call:", error);
        //     throw error;
        // }
    }
    async sendMessage(params) {
        let postBody = this.json("chat.sendMessage", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }         
            })
        });
    }

    async listProductCategories() {
        let postBody = this.json("home.listProductCategories", {});

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }         
            })
        });
    }
    async getTagsTreeFront(params) {
        let postBody = this.json("tags.getTagsTreeFront", params);
        
        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }
            })
        });
    }

    async listCategoryFilters(params) {
        let postBody = this.json("products.ListCategoryFilters", params);
        
        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }
            })
        });
    }
    
    // async getMaxProductPrice(params) {
    //     let postBody = this.json("products.getMaxProductPrice", params);

    //     return new Promise((resolve, reject) => {
    //         axios.post(this.url, postBody)
    //         .then((response) => {
                
    //             if ('data' in response)
    //             {
    //                 let data = response.data;
    //                 if('error' in data)
    //                 {
    //                     let error = data.error;
    //                     reject(error);
    //                 }
    //                 if('result' in data)
    //                 {
    //                     let result = data.result;
    //                     resolve(result);
    //                 }
    //             }  
    //             else
    //             {
    //                 reject("error")
    //             }
    //         })
    //     });
    // }
    async getProducts(params) {
        let postBody = this.json("products.getAllProducts", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                if ('data' in response)
                {
                    let data = response.data;
                    if(typeof data == 'string') {
                        reject({message: 'Internal Error Occurred!'});
                    }
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }
            }).catch((err) => {
                reject(err)
            })
        });
    }
    
    async productSearch(params) {
        let postBody = this.json("products.productSearch", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
            .then((response) => {
                
                if ('data' in response)
                {
                    let data = response.data;
                    if('error' in data)
                    {
                        let error = data.error;
                        reject(error);
                    }
                    if('result' in data)
                    {
                        let result = data.result;
                        resolve(result);
                    }
                }  
                else
                {
                    reject("error")
                }
            })
        });
    }

    async getProductDetails(params) {

        let postBody = this.json("products.getProductDetails",params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async getModalFilters(params) {

        // let params = {
        //     productCode:itemCode
        // }

        let postBody = this.json("products.getProductModelFilters",params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async listCheckoutProcs(params={}) {
        let postBody = this.json("shops.list_checkout_procedures", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async listUDdefs(params={}) {
        let postBody = this.json("shops.list_user_data_defs", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async listUserData(params={}) {
        let postBody = this.json("customers.list_user_data", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async delUserData(params={}) {
        let postBody = this.json("customers.delete_user_data", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async updateCustomerDetails(params={}) {
        let postBody = this.json("customers.update", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async getCustomerDetails(params={}) {
        let postBody = this.json("customers.details", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async orderMail(params={}) {
        let postBody = this.json("orders.mail-pdf-invoice", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async getUserdata(params={}) {
        let postBody = this.json("customers.get_user_data", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async userData(params={}) {
        let postBody = this.json("customers.user_data", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async guestUserData(params={}) {
        let postBody = this.json("customers.guest_user_data", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async listAddresses(params={}) {
        let postBody = this.json("customers.getAddresses", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async addAddress(params) {
        let postBody = this.json("customers.addAddresses", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async editAddress(params) {
        let postBody = this.json("customers.editAddresses", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async delCustomerAddress(params) {
        let postBody = this.json("customers.deleteAddresses", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async getTransaction(params={}) {
        let postBody = this.json("orders.getTransaction", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async saveTransaction(params={}) {
        let postBody = this.json("orders.saveOrderTransaction", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async listOrders(params={}) {
        let postBody = this.json("orders.customerOrders", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async getOrderDetails(params={}) {
        let postBody = this.json("orders.orderDetails", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    async orderReceipt(htmlContent) {
        console.log("Function IS being Called in the be interface.js with the template", htmlContent)
        let postBody = htmlContent
        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    async orderPlace(params={}) {
        let postBody = this.json("orders.orderPlace", params);
        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async listGifts(params={}) {
        let postBody = this.json("gift_card.getCustomerGiftCards", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async listServiceEntities(params={}) {
        let postBody = this.json("country.getShopEntityStatus", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async listEntities(params={}) {
        let postBody = this.json("country.listServiceEntities", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async listCountries(params={}) {
        let postBody = this.json("country.listAllCountries", params);
        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async logout(params={}) {
        let postBody = this.json("customers.logout", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;

                    reject(error.message);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    
    }

    async getUserInfo(params={}) {
        let postBody = this.json("customers.loggedInUserInfo", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;

                    reject(error.message);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    
    }

    async forgotPassword(params={}) {
        let postBody = this.json("users.forgotPassword", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;

                    reject(error.message);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    
    }
    
    async reset_password(params={}) {
        let postBody = this.json("users.reset_password", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;

                    reject(error.message);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    
    }

    async login(params={}) {
        let postBody = this.json("customers.login", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;

                    reject(error.message);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    
    }
    
    async register(params={}) {
        let postBody = this.json("customers.register_customer", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;

                    reject(error.message);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    
    }
    
    async returnOrderItem(params) {
        let postBody = this.json("orders.orderItemAction", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;

                    reject(error.message);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    
    }

    async listLocations(params={}) {
        let postBody = this.json("shop_locations.listingFront", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async getProductByTag(params) {

        // let postBody = this.json("products.getProductDetailsByTag",params);
        let postBody = this.json("products.getProductCodeByTag",params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    traverseTags(attributes, setcode, sets, g_ModelFilters) {
        let ret = [];
        if(attributes != undefined && attributes.length > 0) {
            attributes = this.shopPossibleTags(attributes);

            let attrKeys = Object.keys(sets[setcode]);
            for(let i in attributes) {
                let attr = attributes[i];
                if(attr.status.toLowerCase() == 'public') {
                    let attrKey = attrKeys.indexOf(attr.code);
    
                    if(attrKey > -1) {
                        let label = sets[setcode][attr.code];
                        let tcode = '';
                        
                        let fileValSplit = '","fileName":"';
                        if(label && label.includes(fileValSplit)) {
                            // let lbl = label.split(fileValSplit);
                            // label = {label: '', file: ''};
                            // if(lbl.length > 0) label.label = lbl[0];
                            // if(lbl.length > 1) label.file = lbl[1];
                        } else if(label && label.includes('|')) {
                            label = label.split('|');
                            let lbl = [];
                            for(let j in label) {
                                let ind = attr.attributes.map(function(item){ return item.code }).indexOf(label[j]);
                                if(ind > -1) {
                                    lbl.push(attr.attributes[ind].label);
    
                                    if(i == 0) tcode = attr.attributes[ind].code;
                                }
                            }
                            label = lbl.toString();
                        } else {
                            if(Array.isArray(attr.attributes)) {
                                let ind = attr.attributes.map(function(item){ return item.code }).indexOf(label);
                                if(ind > -1) {
                                    label = attr.attributes[ind].label;
                                    tcode = attr.attributes[ind].code;
                                }
                            }
                        }
                        
                        let opts = [];
                        if(g_ModelFilters && g_ModelFilters[attr.code] && g_ModelFilters[attr.code].length > 1) {
                            if(!attr.attributes) {
                                for(let k in g_ModelFilters[attr.code]) {
                                    let slec = false;
                                    if(k == 0) slec = 'checked';
                                    opts.push({code: attr.code, label: g_ModelFilters[attr.code][k], image: attr.image, selected: slec});
                                }
                            } else {
                                for(let x in attr.attributes) {
                                    if(g_ModelFilters[attr.code].indexOf(attr.attributes[x].code) > -1) {
                                        let slec1 = false;
                                        if(tcode == attr.attributes[x].code) slec1 = 'checked';
                                        opts.push({code: attr.attributes[x].code, label: attr.attributes[x].label, image: attr.attributes[x].image, selected: slec1});
                                    }
                                }
                            }
                        }
                        ret.push({label: attr.label, code: attr.code, image: attr.image, type: attr.type, value: label, options: opts, key_index: attr.key_index});
                    } else {
                        
                        if(attr.attributes) {
                            for(let j in attr.attributes) {
                                attr.attributes[j].parent_Label = attr.label;
                                attr.attributes[j].parent_Code = attr.code;
                                attr.attributes[j].parent_type = attr.type;
                            }
                        }
                        this.traverseTags(attr.attributes, setcode, sets, g_ModelFilters);
                    }
                }
            }
        } else {
            ret = false;
        }

        return ret;
    }

    shopPossibleTags(result) {
        let tempres = []; // filter shop set image attr
        result.forEach(r => {
            let rindex = tempres.findIndex(x => x.id == r.id);
            if(rindex > -1) {
                let o = result.filter(x => x.id == r.id && x.shop_id_image);
                if(o.length) {
                    tempres.splice(rindex, 1);
                    tempres.push(o[0]);
                }
            } else tempres.push(r);
        });
        return tempres;
    }

    tagsToArray(result, sets, g_ModelFilters) {
        let tagslist = [];
        // let self = this;
        if(result.length) {
            let setsKeys = Object.keys(sets);
            for(let i in result) {
                let set = result[i];
                for(let j in setsKeys) {
                    let setKey = setsKeys[j];
                    if(setKey == set.code && set.status.toLowerCase() == 'public') {
                        if(set.attributes) {
                            tagslist.push({title: set.label, code: set.code, image: set.image, children: this.traverseTags(set.attributes, setKey, sets, g_ModelFilters)});
                        }
                    }
                }
            }
        }

        return tagslist;
    }

    async listCustomers(params) {
        let postBody = this.json("customers.list", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async customerLogin(params) {
        let postBody = this.json("customers.customer_login", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async contactUs(params) {
        let postBody = this.json("shops.contactUs", params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
    
    async getPaymentIntent(params) {
        let postBody = this.json('customers.getPaymentIntent', params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async getPayPalClient(params) {
        let postBody = this.json('customers.getPayPalClient', params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }

    async getStripeClient(params) {
        let postBody = this.json('customers.getStripeClient', params);

        return new Promise((resolve, reject) => {
        axios.post(this.url,postBody)
        .then((response) => {
            
            if ('data' in response)
            {
                let data = response.data;
                if('error' in data)
                {
                    let error = data.error;
                    reject(error);
                }
                if('result' in data)
                {
                    let result = data.result;
                    resolve(result);
                }
            }  
            else
            {
                reject("error")
            }         
        })
        
        });
    }
  }

  export default ServerInterface;
<template>
    <div style="justify-content: space-between !important;" :class="'row-'+align" v-if="isProduct && price <= 0">
        <div v-if="label" :style="{'min-width': minWidth+'px'}" :class="{ 'pointer': forAttr }">
            <label :for="forAttr">{{label}} : </label>&nbsp;
        </div>
        <label>Request for Quote</label>
    </div>
    <div  :class="labelAlign " v-else>
    
        <div v-if="label" :style="{'min-width': minWidth+'px'}" style="display: flex; justify-content: space-between;" class="okok">
            <label :for="forAttr">{{label}} : </label>&nbsp;
        </div>
        <div  class="d-flex mobile__Fonts">
            <div >{{currency}}&nbsp;</div>
        <div class="discount" v-if="discount"><s>{{price_evl}}</s>&nbsp;{{discount_evl}}</div>
        <div v-else>{{price_evl}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        forAttr: {String, default: ""},
        label: {String, default: ""},
        align: {String, default: "left"},
        figAlign: {String, default: "left"},
        price: {String, Number, default: 0},
        discount: {Number, default: 0},
        minWidth: {Number, default: 0},
        isProduct: {Boolean, default: false},
    },
    emits: ['requestQoute'],
    computed: {
       labelAlign() {
    let cls = 'row-' + this.align;
    
    // Check if figAlign is explicitly set to a specific value
    if (this.figAlign === 'left') {
        cls += ' justify-content-start';
    } else if (this.figAlign === 'right') {
        cls += ' justify-content-end';
    } else if (this.figAlign === 'between') {
        cls += ' justify-content-between';
    } else if (this.figAlign === 'center') {
        // If figAlign is not set to a specific value, apply center alignment
        cls += ' justify-content-center';
    }

    return cls;
},
        figuresAlign() {
            if(this.figAlign == 'right') return 'auto';
            return '0';
        },
        currency() {
            if(this.$store.state.shopCurrency && this.$store.state.shopCurrency.toLowerCase() == 'usd') return '$';
            else return this.$store.getters.defaultCurrency;
        },
        price_evl() {
            let p = this.price ? parseFloat(this.price).toFixed(2) : '0.00';
            
            if(this.$store.state.shopCurrency && this.$store.state.shopCurrency.toLowerCase() == 'usd') p = (parseFloat(p)/this.$store.state.shopUSDRate).toFixed(2);
            
            return p;
        },
        discount_evl() {
            let p = this.discount ? parseFloat(this.discount).toFixed(2) : '0.00';
            
            if(this.$store.state.shopCurrency && this.$store.state.shopCurrency.toLowerCase() == 'usd') p = (parseFloat(p)/this.$store.state.shopUSDRate).toFixed(2);
            
            return p;
        }
    },
}
</script>

<style scoped>
/* .currency {

  font-weight: 500;
  font-size: .9rem;
  align-self: center;
 
} */
.pointer {
    cursor: pointer;
}
@media (max-width: 768px){
    .mobile__Fonts{
        font-size: 12px !important;
    }
}
.w-100{
    width: 100%;
}
.discount s {
    color: rgb(170, 0, 0);
}
.d-flex{
    display: flex;
}
.justify-content-between{
    justify-content: space-between;
}
</style>
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
    // beforeEnter() {location.href = location.origin}
  // },
  {
    // path: '/products/:cat?/:filterBy?',
    path: '/products/:cat?',
    name: 'Products',
    component: () => import('../views/Products.vue')
  },
  {
    path: '/product/:code',
    name: 'Product',
    component: () => import('../views/product_details_page.vue'),
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/cart.vue'),
  },
  {
    path: '/procedure/:code/:cartIndex',
    name: 'Procedure',
    component: () => import('../views/checkoutprocedure.vue'),
  },
  {
    path: '/userdata/:code/:id?',
    name: 'Userdata',
    component: () => import('../views/userdata.vue'),
  },
  {
    path: '/favourites',
    name: 'Favourites',
    component: () => import('../views/favourites.vue'),
  },
  {
    path: '/checkout/:quote?',
    name: 'Checkout',
    component: () => import('../views/checkout.vue'),
  },
  {
    path: '/checkout_response/:status/:ref/:id?',
    name: 'Checkout Response',
    component: () => import('../views/checkout_response.vue'),
  },
  // {
  //   path: '/balance_response/:status/:ref/:id?',
  //   name: 'Balance Response',
  //   component: () => import('../views/balance_response.vue'),
  // },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard.vue'),
  },
  {
    path: '/orderTracking',
    name: 'OrderTracking',
    component: () => import('../views/OrderTracking.vue'),
  },
  {
    path: '/chatInterface',
    name: 'ChatInterface',
    component: () => import('../views/chatInterface.vue'),
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/pos_customers.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/contactUs.vue')
  },
  {
    path: '/termsandconditions',
    name: 'TermsAndConditions',
    component: () => import('../views/termsandconditions.vue')
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: () => import('../views/privacypolicy.vue')
  },
  {
    path: '/measurePD',
    name: 'Measure PD',
    component: () => import('../views/tryonInfo.vue')
  },
  {
    path: '/tryonInfo',
    name: 'Tryon Info',
    component: () => import('../views/tryonInfo.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/blog')
  },
  {
    path: '/blogdetail',
    name: 'Blog Detail',
    component: () => import('../components/blogdetail')
  },
  {
    path: '/help',
    name: 'Help us',
    component: () => import('../views/help')
  },
  {
    path: '/cancellationpolicy',
    name: 'Cancellation & refund policy ',
    component: () => import('../views/Cancellation')
  },
  {
    path: '/measureframe',
    name: 'Measure Frame',
    component: () => import('../views/measureframe')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/faq')
  },
  {
    path: '/faceshape',
    name: 'Face Shape',
    component: () => import('../views/faceshape')
  },
  {
    path: '/reset_password',
    name: 'Reset Password',
    component: () => import('../views/reset_password')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
})

export default router

<template>
    <div v-html="footer"></div>
</template>
<script>
export default {
    data() {
        return {
            footer: ''
        }
    },
    beforeCreate() {
        fetch(this.$store.getters.getS3HtmlUrl(`footer`), {headers: {'Cache-Control': 'no-cache'}})
        .then((r) => {
			r.text().then((c) => {this.footer = c;});
        }).catch((err) => {
            console.log(err);
        });
    }
}
</script>
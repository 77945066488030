<template>
  <div>
    <top_header />

    <div class="main-div scrollbar-style" @click="$store.commit('setShowSubMenu', false)">
      <router-view />
        
        <!-- <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="$route.path" />
          </transition>
        </router-view> -->

        <tsp_footer />
    </div>

    <!-- Whats App-->
    <wa/> 

    <!-- tryon canvas -->
    <canvas style="display: none;" ref="tryon-canvas"></canvas>
  </div>
</template>

<script>
import top_header from './components/navigate/top_header.vue'
import tsp_footer from './components/navigate/footer.vue'
import wa from './components/wa.vue'
import initStore from './store/initStoreValues.js'

import ServerInterface from '@/interface/be_interface.js';

export default {
  name: 'App',
  inject:['tryon'],
  components: {
    top_header,
    tsp_footer,
    wa
  },
  data() {
    return {
      hlcolor: this.$store.state.highlightColor
    }
  },
  watch: {
    '$store.state.shopInfo': function(val) {
      let businessCat = val.business_category;

      if(this.$store.getters.isTryOnCategory) {
        let tryon_canvas = this.$refs['tryon-canvas']
        this.tryon.init(businessCat, tryon_canvas).then((r) => {
          this.$store.commit('setInitTryOn', r);
        });
      }
    }

  },
  methods: {
    loadSessionProducts(sessionItems, storeItems) {
      let items = [];
      if(sessionItems && sessionItems.length) {
        items = sessionItems;

        if(storeItems.length) {
          items = [];
          for(let item1 of sessionItems) {
            for(let item2 of storeItems) {
              if(item1.id != item2.id || item1.shopCode != item2.shopCode) {
                items.push(item1);
              }
            }
          }
        }
        
        if(items.length) {
          let tempItems = items;
          items = [];
          tempItems.forEach(itm => {
            if(!items.length) items.push(itm);
            else {
              if(!items.filter(x => x.id == itm.id && x.shopCode == itm.shopCode).length) items.push(itm);
            }
          });
          let s = new ServerInterface(this.$store.state.url, this.$store.state.shopCode);
          s.getProducts({cart: items}).then(r => {
            if(r && r.list) {
              for(let pd of r.list) {
                storeItems.push({...pd, ...{quantity: 1, msg: ''}});
              }
            }
          })
        }
      }
      
      return storeItems;
      // return storeItems.filter((value, index, self) =>
      //   index === self.findIndex((t) => (
      //     t.id === value.id && t.shopCode === value.shopCode
      //   ))
      // );
    }
  },
  created() {
    if(sessionStorage.getItem('sessionItems')) {
      let sessionProducts = JSON.parse(sessionStorage.getItem('sessionItems'));

      this.$store.commit('setCartItems', this.loadSessionProducts(sessionProducts.cart, this.$store.state.cartItems));

      let fitems = []
      let tempItems = this.loadSessionProducts(sessionProducts.fav, this.$store.state.favItems);
      tempItems.forEach(itm => {
        if(!fitems.length) fitems.push(itm);
        else {
          if(!fitems.filter(x => x.id == itm.id && x.shopCode == itm.shopCode).length) fitems.push(itm);
        }
      });

      this.$store.commit('setFavItems', fitems);

      sessionProducts.cart = [];
      sessionStorage.setItem('sessionItems', JSON.stringify(sessionProducts));
    }
  },
  beforeCreate() {
    // this.$store.commit('setShowSubMenu', false);

    let i = new initStore();
    i.init();

    this.$store.dispatch('getShopDetails');
    this.$store.dispatch('loggedInUserInfo');
    this.$store.dispatch('listCountries');
    
    let cldfrontUrl = this.$store.state.s3Url;

    let favicon = document.createElement("link");
    favicon.setAttribute("rel", "icon");
    favicon.setAttribute("id", "favicon");
    favicon.setAttribute("href", `${cldfrontUrl}/shop_landing_page/shop_primary_logo.png`, {headers: {'Cache-Control': 'no-cache'}});
    document.getElementsByTagName("head")[0].appendChild(favicon);

    let ColorSchemeElement = document.createElement("link");
    ColorSchemeElement.setAttribute("rel", "stylesheet");
    ColorSchemeElement.setAttribute("type", "text/css");
    ColorSchemeElement.setAttribute("href", `${cldfrontUrl}/shop_landing_page/color_scheme/style.css`, {headers: {'Cache-Control': 'no-cache'}});
    document.getElementsByTagName("head")[0].appendChild(ColorSchemeElement);

    fetch(`${cldfrontUrl}/shop_landing_page/color_scheme/style.css`, {headers: {'Cache-Control': 'no-cache'}})
    // fetch('/assets/color_scheme/parse.css', {headers: {'Cache-Control': 'no-cache'}})
    .then((r) => {
      r.text().then((c) => {
          try {
            let json = '';
            let selectorName = '';
            let propertyKey = '';
            let propertyVal = '';
            for(let i in c) {
              if(c[i] == '{') {
                json += '"'+selectorName.trim()+'":{';
                selectorName = '';
                propertyKey = '';
                propertyVal = '';
              } else if(c[i] == '}') {
                if(json[json.length-1] == ',') json = json.slice(0, -1);
                json += '}';
                if(i < c.length-1) json += ',';
                selectorName = '';
                propertyKey = '';
                propertyVal = '';
              } else if(c[i] == ':' && c.substring(parseInt(i)+1, parseInt(i)+6) != 'hover') {
                json += '"'+propertyKey.trim()+'"'+':';
                selectorName = '';
                propertyKey = '';
                propertyVal = '';
              } else if(c[i] == ';') {
                json += '"'+propertyVal.trim()+'",';
                selectorName = '';
                propertyKey = '';
                propertyVal = '';
              } else if(c[i] && c[i] != '.') {
                selectorName += c[i];
                propertyKey += c[i];
                propertyVal += c[i];
              }
            }
            let colorJson = JSON.parse('{'+json+'}');

            this.hlcolor = colorJson["primary-color"]["background-color"];
            this.$store.commit('setHighlightColor', colorJson["primary-color"]["background-color"]);
          } catch(ex) {
            console.error(ex);
          }
      });
    }).catch((err) => {
        console.log(err);
    });

    let fontElement = document.createElement("link");
    fontElement.setAttribute("rel", "stylesheet");
    fontElement.setAttribute("type", "text/css");
    fontElement.setAttribute("href", `${cldfrontUrl}/shop_landing_page/css/fontFamily.css`);
    document.getElementsByTagName("head")[0].appendChild(fontElement);

    let footer = document.createElement("link");
    footer.setAttribute("rel", "stylesheet");
    footer.setAttribute("type", "text/css");
    footer.setAttribute("href", `${cldfrontUrl}/shop_landing_page/css/footer.css`);
    document.getElementsByTagName("head")[0].appendChild(footer);

    fetch(`${cldfrontUrl}/header_menu/menu.json`, {headers: {'Cache-Control': 'no-cache'}}).then(r => {
      r.text().then(j => {
        if(j && JSON.parse(j)) {
          this.$store.commit('setHeaderFilters', JSON.parse(j));
        }
      });
    }).catch(e => {
      console.log(e);
    });
  }
}

</script>

<style>
@import "../public/fonts/stylesheet.css";
@import "../public/css/main.css";
/* @import "../public/assets/color_scheme/style.css"; */
/* @import "../public/assets/color_scheme/style_new.css"; */

/* price filter slider background color */
.slider-base {
  display: flex !important;
  /* justify-content: space-between; */
  /* width: 5rem; */
  height: 5px !important;
  background: inherit !important;
}
.slider-connects {
  margin-left: 1.75rem !important;
  background: inherit !important;
}
.slider-handle-lower{
  width: 10px !important;
  padding-inline: 1.5rem;
}
@media (max-width: 1800px) and (min-width: 1650px){
  .slider-handle-upper{
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 6rem !important;
}
}
@media(max-width: 1800px) and (min-width: 1400px){
  .slider-handle-lower{
  width: 10px !important;
  padding-inline: 2.3rem;
}
}
@media(max-width: 1380px) and (min-width: 1200px){
  .slider-handle-lower{
  width: 10px !important;
  padding-inline: 2rem;
}
}
@media(max-width: 1200px) and (min-width: 980px){
  .slider-handle-lower{
  width: 10px !important;
  padding-inline: 3.1rem;
}
}
@media(max-width: 900px) and (min-width: 800px){
  .slider-handle-lower{
  width: 10px !important;
  padding-inline: 3.1rem;
}
.slider-handle-upper{
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 1rem !important;
}
}
@media(max-width: 800px) and (min-width: 768px){
  .slider-handle-lower{
  width: 10px !important;
  padding-inline: 2.1rem;
}
.slider-handle-upper{
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 1rem !important;
}
}
@media(max-width: 400px){
  .slider-handle-lower{
  padding-inline: 1.3rem !important;
}
}
@media(max-width: 420px) and (min-width:400px){
  .slider-handle-lower{
  padding-inline: 0.4rem !important;
}
}
@media(max-width: 450px) and (min-width:420px){
  .slider-handle-lower{
  padding-inline: 0rem !important;
}
}
@media(max-width: 1198px) and (min-width: 1145px){
  .slider-handle-upper{
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 6rem !important;
}
}
.slider-handle-upper{
  position: relative;
  z-index: 10000;
  /* width: 12rem !important;
  margin-left: -10rem; */
  display: flex;
  justify-content: flex-end;
}
.slider-origin{
  width: 5rem;
}
.slider-connect {
  background: inherit !important;
}
.slider-touch-area{
  margin-top: -5px;
  background: rgb(202, 202, 202) !important;
  width: 1rem;
  height: 1rem;
  border: 2px solid #CCCCCC;
  border-radius: 50%;
}
.slider-tooltip{
  display: none;
}
.v-input__details, .v-messages{
display: none !important;
}
/* Slider Mobile View Settings */
@media (max-width : 768px){
  /* price filter slider background color */
.slider-base {
  display: flex !important;
  justify-content: space-between;
  /* width: 5rem; */
  height: 5px !important;
  background: inherit !important;
}
.slider-connects {
  margin-left: -3.5rem !important;
  background: inherit !important;
}
.slider-handle-upper{
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.slider-origin{
  width: 5rem;
}
.slider-connect {
  background: inherit !important;
}
.slider-touch-area{
  margin-top: -5px;
  background: rgb(202, 202, 202) !important;
  width: 1rem;
  height: 1rem;
  border: 2px solid #CCCCCC;
  border-radius: 50%;
}
.slider-tooltip{
  display: none;
}
}

.row {
  display: flex;
  flex-direction: row;
}
.main-div {
  height: calc(100vh - 70px);
  /* overflow: auto; */
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.swal2-container {
  z-index: 999999 !important;
}
.carousel__next, .carousel__prev {
  background-color: v-bind(hlcolor) !important;
}
</style>
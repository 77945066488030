<template>
    <div class="pos-header" v-if="$store.state.sales_manager_token">
        <div class="header-contents">
            <button class="primary-button button-slim" :class="{active: isActive==c.id, 'rred-bordered': isActive==c.id, ['context-'+(index+1)]: isActive==c.id}" v-for="(c, index) in list" :key="c" @click="select_context(c)">
                <span v-if="isActive==c.id"><fa class="card-icons p-0 fa-lg icon-edit tick-Green" icon="check"/></span>
                {{c.first_name}} {{c.last_name}}
            </button>
        </div>

        <div class="header-contents">
            <button class="primary-button button-slim" @click="admin_Router">Admin</button>
            <button class="primary-button button-slim" @click="customer_search">Customers</button>
            <!-- <fa style="margin-left: 10px; cursor: pointer;" icon="sign-out-alt" @click.prevent.stop="logout()"/> -->
        </div>
    </div>
</template>

<script>

export default {
    methods:{
        customer_search() {
            this.$router.push({path: '/customers'});
        },
        admin_Router() {
            //     this.$router.push({path: '/admin/index.html'});
            //     setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // location.href = "/admin/index.html?v="+this.$store.state.sales_manager_token;
            
            window.location = '/admin/dashboard.html';
        },
        select_context(c) {
            this.$store.dispatch('setPos', c);
            let routes = ['Dashboard', 'Customers', 'About', 'Home', 'Products', 'Product'];
            if(routes.indexOf(this.$route.name) == -1) {
                this.$router.push({path: '/products'});
            }
        },
        logout() {
            this.$store.dispatch('logout', this.$store.state.sales_manager_token);
        }
        // randColor(i) {
        //     let n = (Math.random()*255)+(i+50);
        //     return 'rgb(146, 109, '+n+')';
        // },
    },
    computed:{
        logo:function() {
            return this.$store.state.logo;
        },
        list() {
            return this.$store.state.posCustomers;
        },
        isActive() {
            let ind = this.$store.state.posCustomers.findIndex(x => x.token == this.$store.state.customer_token);
            if(ind > -1) return this.$store.state.posCustomers[ind].id;
            return false;
        }
    },
    created() {
        if(!this.$store.state.sales_manager_token && this.$route.name == 'Customers') {
            this.$router.push({ path: '/dashboard' });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pos-header {
    position: absolute;
    width: 100%;
    height: 58px;
    background: cadetblue;
    display: flex;
    justify-content: space-between;
}
.header-contents {
    margin: 8px;
}
.context-1 {
    background: rgb(127, 189, 65);
    color: rebeccapurple;
}

.context-2 {
    background: rgb(196, 127, 127);
    color: rebeccapurple;
}

.context-3 {
    background: rgb(146, 109, 194);
    color: rebeccapurple;
}

.context-4 {
    background: rgb(127, 177, 211);
    color: rebeccapurple;
}
.rred-bordered{
    border: 4px solid red;
}
.tick-Green{
  color : rgb(0, 255, 0);
}
.active{
    height: 43px !important;
    border:  2px solid red !important;
}
</style>
